import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import * as ChartOptions from '../components/dataSourceComponents/ChartOptions'
import { type Options } from 'highcharts'

interface ChannelDataOptions {
  consumptionChartOptions: Options
}
export interface signalState {
  isLoading: boolean
  loadingError: string | null
  channelDataOptions: ChannelDataOptions | null
  rawData: boolean
  httpStatus: number | null
}

// Initial state of the slice
const initialState: signalState = {
  isLoading: true,
  loadingError: null,
  channelDataOptions: {
    consumptionChartOptions: ChartOptions.getCellularOptions()
  },
  rawData: false,
  httpStatus: null
}

// Slice to return from action
export const cellularSignalSlice = createSlice({
  name: 'cellularSignalMap',
  initialState,
  reducers: {
    signalDataLoadingAction: (state) => {
      // clear out the data whenever we reload
      return {
        isLoading: true,
        loadingError: null,
        channelDataOptions: null,
        rawData: false,
        httpStatus: 0
      }
    },
    signalDataUpdateAction: (state, action: PayloadAction<{ data: ChannelDataOptions, httpStatus: number }>) => {
      return {
        isLoading: false,
        loadingError: null,
        channelDataOptions: action.payload.data,
        rawData: false,
        httpStatus: action.payload.httpStatus
      }
    },
    signalDataErrorAction: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        isLoading: false,
        loadingError: action.payload.errorMessage,
        channelDataOptions: null,
        rawData: false,
        httpStatus: action.payload.httpStatus
      }
    },
    rawDataUpdateAction: (state, action: PayloadAction<{ raw: boolean, httpStatus: number }>) => {
      return {
        isLoading: false,
        loadingError: null,
        channelDataOptions: null,
        rawData: action.payload.raw,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

// Actions to export
export const {
  signalDataLoadingAction,
  signalDataUpdateAction,
  signalDataErrorAction,
  rawDataUpdateAction
} = cellularSignalSlice.actions

export default cellularSignalSlice.reducer
