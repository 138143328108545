/* eslint-disable */
import { type AppDispatch } from '../../../store'
import * as AllConfigDataService from '../services/allConfigService'
import * as FileStatus from '../services/fileStatusService'
import * as CreateConfigurationService from '../services/createConfigService'
import * as SingleConfigurationService from '../services/singleConfigService'
import * as UpdateConfigurationService from '../services/updateConfigService'
import * as DeleteConfigurationService from '../services/deleteConfigService'
import * as schdulerListDataService from '../services/getAllSchdulerList'
import * as reportSchedulerById from '../services/getReportSchedulerByIdService'
import * as CreateReportTemplateService from '../services/createReportTemplateService'
import * as TemplateDetailsListService from '../services/templateDetailsList'
import * as ReportContentColumnService from '../services/reportContentColumnService'
import * as EditReportTemplateService from '../services/editReportTemplateService'
import * as DeleteReportTemplateService from '../services/deleteReportTemplateService'
import * as DeleteSchdeulerTemplateService from '../services/deleteSchedulerService'
import * as SchedulerDropDownService from '../services/getAllCreateSchdulerDropdown'
import * as CreateSchedulerService from '../services/createReportSchedulerService'
import * as EditReportSchedulerService from '../services/editReportSchedulerService'
import * as getAllSchedulerFilterDropdownService from '../services/reportSchedulerGridFilterServices'
import * as getAllSchedulerListByFilterServcie from '../services/getSchedulerListByFilterParameter'
import * as exportSchedulerGridDataService from '../services/exportSchedulerGridService'
import * as CreateSFTPConfigurationService from '../services/CreateSftpConfigurationService'
import * as ConfigurationDropdownMetadataService from '../services/ConfigurationMetadataService'
import * as ConfigurationListDataService from '../services/getConfigurationProtocolList'
import * as UpdateConfigurationProtocolService from '../services/updateConfigurationProtocolService'
import { updateConfigurationProtocolLoadingAction, updateConfigurationProtocolUpdateAction, updateConfigurationProtocolErrorAction } from './updateConfigurationProtocolSlice'
import { ConfigurationListLoadingAction, ConfigurationListUpdateAction, ConfigurationListErrorAction } from './getConfigurationProtocolListSlice'
import { ConfigurationMetadataLoadingAction, ConfigurationMetadataUpdateAction, ConfigurationMetadataErrorAction } from './configurationMetaDataSlice'
import { createSFTPConfigurationLoadingAction, createSFTPConfigurationUpdateAction, createSFTPConfigurationErrorAction } from './CreateSftpConfigurationSlice'
import { createConfigurationLoadingAction, createConfigurationUpdateAction, createConfigurationErrorAction } from './addNewConfigSlice'
import { type AllConfigData, allConfigDataErrorAction, allConfigDataLoadingAction, allConfigDateUpdateAction } from './allConfigSlice'
import { fileStatusDataLoadingAction, fileStatusDateUpdateAction, fileStatusDataErrorAction } from './fileStatusSlice'
import { singleConfigDataErrorAction, singleConfigDataLoadingAction, singleConfigDateUpdateAction } from './singleConfigSlice'
import { updateConfigurationErrorAction, updateConfigurationLoadingAction, updateConfigurationUpdateAction } from './updateConfigSlice'
import { deleteConfigurationErrorAction, deleteConfigurationLoadingAction, deleteConfigurationUpdateAction } from './deleteConfigSlice'
import { SchdulerListLoadingAction, SchdulerListUpdateAction, SchdulerListErrorAction } from './getAllSchdulerListSlice'
import { SchdulerDetailsLoadingAction, SchdulerDetailsUpdateAction, SchdulerDetailsErrorAction } from './getReportSchedulerByIdSlice'
import { SchdulerDropDownLoadingAction, SchdulerDropDownUpdateAction, SchdulerDropDownErrorAction } from './getAllCreateSchdulerDropdownSlice'
import { type ReportData, type ReportContentColumn, type CreateReportData, type ReportSchedulerData, type SFTPConfigurationData, type EditReportSchedulerData } from '../types'
import { createReportTemplateErrorAction, createReportTemplateLoadingAction, createReportTemplateUpdateAction } from './createReportTemplateSlice'
import { templateDetailsListErrorAction, templateDetailsListLoadingAction, templateDetailsListUpdateAction } from './templateDetailsListSlice'
import { ReportContentColumnErrorAction, ReportContentColumnLoadingAction, ReportContentColumnUpdateAction } from './reportContentColumnSlice'
import { editReportTemplateErrorAction, editReportTemplateLoadingAction, editReportTemplateUpdateAction } from './editReportTemplateSlice'
import { deleteReportTemplateErrorAction, deleteReportTemplateLoadingAction, deleteReportTemplateUpdateAction } from './deleteReportTemplateSlice'
import { createReportSchedulerTemplateErrorAction, createReportSchedulerTemplateLoadingAction, createReportSchedulerTemplateUpdateAction } from './createReportSchedulerTemplateSlice'
import { editReportSchedulerTemplateLoadingAction, editReportSchedulerTemplateUpdateAction, editReportSchedulerTemplateErrorAction } from './editReportSchedulerSlice'
import { SchedulerFilterDropDownLoadingAction, SchedulerFilterDropDownUpdateAction, SchedulerFilterDropDownErrorAction } from './reportSchedulerFilterDropdownSlice'
import { deleteSchedulerTemplateLoadingAction, deleteSchedulerTemplateUpdateAction, deleteSchedulerTemplateErrorAction } from './deleteSchedulerSlice'
import { exportSchedulerDataLoadingAction, exportSchedulerDataErrorAction, exportSchedulerDataUpdateAction } from './exportSchedulerDataSlice'
import * as pauseSchedulerService from '../services/pauseAndResumeSchedulerService'
import * as resumeSchedulerService from '../services/pauseAndResumeSchedulerService'
import { pauseSchedulerTemplateLoadingAction, pauseSchedulerTemplateUpdateAction, pauseSchedulerTemplateErrorAction } from './pauseSchedulerSlice'
import { resumeSchedulerTemplateLoadingAction, resumeSchedulerTemplateUpdateAction, resumeSchedulerTemplateErrorAction } from './resumeSchedulerSlice'
import * as schedulerHistoryservice from '../services/getSchedulerHistoryByIdService'
import * as schedulerHistoryBySearchService from '../services/getSchedulerHistoryBySearchService'
import { SchdulerHistoryListLoadingAction, SchdulerHistoryListUpdateAction, SchdulerHistoryListErrorAction } from '../redux/getSchedulerHistoryDetailsSlice'
import * as getSchedulerListBySearch from '../services/getSchedulerListBySearch'
import * as getPublicKeyService from '../services/getPublicKeyService'
import * as getProtocolDetailsService from '../services/getProtocolsDetailsById'
import { PublicKeyDataLoadingAction, PublicKeyDataUpdateAction, PublicKeyDataErrorAction } from '../redux/getPublicKeySlice'
import { ProtocolsDetailsLoadingAction, ProtocolsDetailsUpdateAction, ProtocolsDetailsErrorAction } from './getProtocolDetailsByIdSlice'

export const getProtocolDetailsById = (protocolId: string) => async (dispatch: any) => {
  dispatch(ProtocolsDetailsLoadingAction())
  try {
    await getProtocolDetailsService.getProtocolDetailsById(protocolId, (ProtocolsDetails, httpStatus) => {
      dispatch(ProtocolsDetailsUpdateAction({ data: ProtocolsDetails, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(ProtocolsDetailsErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(ProtocolsDetailsErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const getPublicKeyData = () => async (dispatch: any) => {
  dispatch(PublicKeyDataLoadingAction())
  void getPublicKeyService.getPublicKey((PublicKeyData, httpStatus) => {
    dispatch(PublicKeyDataUpdateAction({ data: PublicKeyData, httpStatus }))
  },
  (errorMessage, httpStatus) => {
    dispatch(PublicKeyDataErrorAction({ errorMessage, httpStatus }))
  })
}

export const getSchdulerHistoryListDetails = (schedulerId: string, schedulerStatus: string, startDate:string, endDate: string) => async (dispatch: any) => {
  dispatch(SchdulerHistoryListLoadingAction())
  try {
    await schedulerHistoryservice.getSchedulerHistoryByIdService(schedulerId, schedulerStatus, startDate, endDate, (SchdulerHistoryListData, httpStatus) => {
      dispatch(SchdulerHistoryListUpdateAction({ data: SchdulerHistoryListData.reportSchedulerDetails, total_count: SchdulerHistoryListData.totalCount, successCount: SchdulerHistoryListData.successCount, errorCount: SchdulerHistoryListData.errorCount, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(SchdulerHistoryListErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(SchdulerHistoryListErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const getSchdulerHistoryListDetailsBySearch = (schedulerId: string, searchParam: string) => async (dispatch: any) => {
  dispatch(SchdulerHistoryListLoadingAction())
  try {
    await schedulerHistoryBySearchService.getSchedulerHistoryByIdService(schedulerId, searchParam, (SchdulerHistoryListData, httpStatus) => {
      dispatch(SchdulerHistoryListUpdateAction({ data: SchdulerHistoryListData.reportSchedulerDetails, total_count: SchdulerHistoryListData.totalCount, successCount: SchdulerHistoryListData.successCount, errorCount: SchdulerHistoryListData.errorCount, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(SchdulerHistoryListErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(SchdulerHistoryListErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const pauseSchedulerTemplate = (templateId: string) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(pauseSchedulerTemplateLoadingAction())

  void pauseSchedulerService.pauseSchedulerService(
    templateId,
    (httpStatus) => {
      dispatch(pauseSchedulerTemplateUpdateAction(httpStatus))
    },
    (httpStatus) => {
      dispatch(pauseSchedulerTemplateErrorAction(httpStatus))
    }
  )
}

export const resumeSchedulerTemplate = (templateId: string) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(resumeSchedulerTemplateLoadingAction())

  void resumeSchedulerService.resumeSchedulerService(
    templateId,
    (httpStatus) => {
      dispatch(resumeSchedulerTemplateUpdateAction(httpStatus))
    },
    (httpStatus) => {
      dispatch(resumeSchedulerTemplateErrorAction(httpStatus))
    }
  )
}

export const getSchdulerListDetailsBySearch = (page: number, size: number, sortdir: string, sortfield: string, search: string, tenantId: string) => async (dispatch: any) => {
  dispatch(SchdulerListLoadingAction())
  try {
    await getSchedulerListBySearch.getAllSchdulerListBySearch(page, size, sortdir, sortfield, search, tenantId, (SchdulerListData, httpStatus) => {
      dispatch(SchdulerListUpdateAction({ data: SchdulerListData.reportSchedulerDetails, total_count: SchdulerListData.totalCount, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(SchdulerListErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(SchdulerListErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const getSchdulerListDetails = (page: number, size: number, sortdir: string, sortfield: string, search: string, tenantId: string) => async (dispatch: any) => {
  dispatch(SchdulerListLoadingAction())
  try {
    await schdulerListDataService.getAllSchdulerList(page, size, sortdir, sortfield, search, tenantId, (SchdulerListData, httpStatus) => {
      dispatch(SchdulerListUpdateAction({ data: SchdulerListData.reportSchedulerDetails, total_count: SchdulerListData.totalCount, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(SchdulerListErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(SchdulerListErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const getReportSchedulerById = (reportSchedulerId: string) => async (dispatch: any) => {
  dispatch(SchdulerDetailsLoadingAction())
  try {
    await reportSchedulerById.getReportSchedulerById(reportSchedulerId, (SchdulerData, httpStatus) => {
      dispatch(SchdulerDetailsUpdateAction({ data: SchdulerData, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(SchdulerDetailsErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(SchdulerDetailsErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const getConfigurationListDetails = (sortdir: string, sortfield: string) => async (dispatch: any) => {
  dispatch(ConfigurationListLoadingAction())
  try {
    await ConfigurationListDataService.getConfigurationProtocolList(sortdir, sortfield, (ConfigurationListData, httpStatus) => {
      dispatch(ConfigurationListUpdateAction({ data: ConfigurationListData.protocolDetails, total_count: ConfigurationListData.totalCount, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(ConfigurationListErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(ConfigurationListErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const getAllSchdulerListByFilterPara = (page: number, size: number, sortdir: string, sortfield: string, search: string, tenantId: string, filterParams: any) => async (dispatch: any) => {
  dispatch(SchdulerListLoadingAction())
  try {
    await getAllSchedulerListByFilterServcie.getAllSchdulerListByFilterPara(page, size, sortdir, sortfield, search, tenantId, filterParams, (SchdulerListData, httpStatus) => {
      dispatch(SchdulerListUpdateAction({ data: SchdulerListData.reportSchedulerDetails, total_count: SchdulerListData.totalCount, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(SchdulerListErrorAction({ errorMessage, httpStatus }))
    })
  } catch (e: any) {
    dispatch(SchdulerListErrorAction({ errorMessage: e.response.message, httpStatus: e.response.status }))
  }
}

export const deleteSchedulerTemplate = (templateId: string) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(deleteSchedulerTemplateLoadingAction())

  void DeleteSchdeulerTemplateService.deleteScheduler(
    templateId,
    (httpStatus) => {
      dispatch(deleteSchedulerTemplateUpdateAction(httpStatus))
    },
    (httpStatus) => {
      dispatch(deleteSchedulerTemplateErrorAction(httpStatus))
    }
  )
}

export const exportSchedulerGridTemplate = (tenantId: string) => async (dispatch: any) => {
  dispatch(exportSchedulerDataLoadingAction())
  try {
    await exportSchedulerGridDataService.exportSchedulerGrid(tenantId, (message: any) => {
      dispatch(exportSchedulerDataUpdateAction(message))
    },
    (errorMessage: any) => {
      dispatch(exportSchedulerDataErrorAction(errorMessage))
    })
  } catch (e: any) {
    dispatch(exportSchedulerDataErrorAction(e))
  }
}

export const getAllSchedulerFilterDropdown = (tenantId: string) => async (dispatch: any) => {
  dispatch(SchedulerFilterDropDownLoadingAction())
  void getAllSchedulerFilterDropdownService.getAllSchedulerFilterDropdown(tenantId, (SchedulerFilterDropDownListData, httpStatus) => {
    dispatch(SchedulerFilterDropDownUpdateAction({ data: SchedulerFilterDropDownListData, httpStatus }))
  },
  (errorMessage, httpStatus) => {
    dispatch(SchedulerFilterDropDownErrorAction({ errorMessage, httpStatus }))
  })
}

export const getAllCreateSchdulerDropdown = () => async (dispatch: any) => {
  dispatch(SchdulerDropDownLoadingAction())
  void SchedulerDropDownService.getAllCreateSchdulerDropdown((SchdulerDropDownListData, httpStatus) => {
    dispatch(SchdulerDropDownUpdateAction({ data: SchdulerDropDownListData, httpStatus }))
  },
  (errorMessage, httpStatus) => {
    dispatch(SchdulerDropDownErrorAction({ errorMessage, httpStatus }))
  })
}

export const getConfigurationDropdownMetadata = () => async (dispatch: any) => {
  dispatch(ConfigurationMetadataLoadingAction())
  void ConfigurationDropdownMetadataService.getConfigurationMetadata((ConfigurationMetadata, httpStatus) => {
    dispatch(ConfigurationMetadataUpdateAction({ data: ConfigurationMetadata, httpStatus }))
  },
  (errorMessage, httpStatus) => {
    dispatch(ConfigurationMetadataErrorAction({ errorMessage, httpStatus }))
  })
}

export const CreateSFTPConfigurationservice = (createReportSchedulerTemplatePostRequest: SFTPConfigurationData) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(createSFTPConfigurationLoadingAction())
  void CreateSFTPConfigurationService.CreateSFTPConfiguration(
    createReportSchedulerTemplatePostRequest,
    (httpStatus: number) => {
      dispatch(createSFTPConfigurationUpdateAction(httpStatus))
    },
    (errorMessage, httpStatus) => {
      dispatch(createSFTPConfigurationErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const updateConfigurationProtocolservice = (protocolId: string, UpdateConfigirationFetchRequest: SFTPConfigurationData) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(updateConfigurationProtocolLoadingAction())
  void UpdateConfigurationProtocolService.UpdateConfigurationProtocol(protocolId, UpdateConfigirationFetchRequest,
    (httpStatus: number) => {
      dispatch(updateConfigurationProtocolUpdateAction(httpStatus))
    },
    (errorMessage, httpStatus) => {
      dispatch(updateConfigurationProtocolErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const createReportSchedulerTemplate = (createReportSchedulerTemplatePostRequest: ReportSchedulerData) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(createReportSchedulerTemplateLoadingAction())
  void CreateSchedulerService.createReportScheduler(
    createReportSchedulerTemplatePostRequest,
    (httpStatus: number) => {
      dispatch(createReportSchedulerTemplateUpdateAction(httpStatus))
    },
    (errorMessage, httpStatus) => {
      dispatch(createReportSchedulerTemplateErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const editReportSchedulerTemplate = (reportSchedulerId: string, editReportSchedulerPutRequest: EditReportSchedulerData) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(editReportSchedulerTemplateLoadingAction())
  void EditReportSchedulerService.editReportScheduler(
    reportSchedulerId,
    editReportSchedulerPutRequest,
    (httpStatus: number) => {
      dispatch(editReportSchedulerTemplateUpdateAction(httpStatus))
    },
    (errorMessage, httpStatus) => {
      dispatch(editReportSchedulerTemplateErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchAllConfigs = (page: number, size: number, tenantId: string) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(allConfigDataLoadingAction())

  void AllConfigDataService.getAllConfigData(
    page,
    size,
    tenantId,
    (allConfigData, httpStatus) => {
      dispatch(
        allConfigDateUpdateAction({
          data: allConfigData,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(allConfigDataErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchAllFiles = (page: number, size: number, tenantId: string) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(fileStatusDataLoadingAction())

  void FileStatus.getAllFiles(
    page,
    size,
    tenantId,
    (allConfigData, httpStatus) => {
      dispatch(
        fileStatusDateUpdateAction({
          data: allConfigData,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(fileStatusDataErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const createNewConfig = (configurationTemplate: AllConfigData) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(createConfigurationLoadingAction())

  void CreateConfigurationService.createConfiguration(
    configurationTemplate,
    (createConfigurationResponse) => {
      dispatch(createConfigurationUpdateAction({ data: createConfigurationResponse }))
    },
    (errorMessage, httpStatus) => {
      dispatch(createConfigurationErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchSingleConfig = (configId: string) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(singleConfigDataLoadingAction())

  void SingleConfigurationService.getSingleConfigData(
    configId,
    (singleConfigData, httpStatus) => {
      dispatch(
        singleConfigDateUpdateAction({
          data: singleConfigData,
          httpStatus
        })
      )
    },
    (errorMessage, httpStatus) => {
      dispatch(singleConfigDataErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const updateConfig = (configId: string, configurationTemplate: AllConfigData) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(updateConfigurationLoadingAction())

  void UpdateConfigurationService.updateConfigData(
    configId,
    configurationTemplate,
    (updateConfigurationResponse) => {
      dispatch(updateConfigurationUpdateAction({ data: updateConfigurationResponse }))
    },
    (errorMessage, httpStatus) => {
      dispatch(updateConfigurationErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const deleteConfig = (configId: string) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(deleteConfigurationLoadingAction())

  void DeleteConfigurationService.deleteConfigData(
    configId,
    (deleteConfigurationResponse) => {
      dispatch(deleteConfigurationUpdateAction({ data: deleteConfigurationResponse }))
    },
    (errorMessage, httpStatus) => {
      dispatch(deleteConfigurationErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const createReportTemplate = (createReportTemplatePostRequest: CreateReportData) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(createReportTemplateLoadingAction())

  void CreateReportTemplateService.createReportTemplate(
    createReportTemplatePostRequest,
    (httpStatus: number) => {
      dispatch(createReportTemplateUpdateAction(httpStatus))
    },
    (errorMessage, httpStatus) => {
      dispatch(createReportTemplateErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchTemplateDetailsList = () => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(templateDetailsListLoadingAction())

  void TemplateDetailsListService.getTemplateDetailsList(
    (templateDetailsList: ReportData[], httpStatus: number) => {
      dispatch(templateDetailsListUpdateAction({ data: templateDetailsList, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(templateDetailsListErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const fetchReportContentColumns = () => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(ReportContentColumnLoadingAction())

  void ReportContentColumnService.getReportContentColumns(
    (ReportContentColumn: ReportContentColumn[], httpStatus: number) => {
      dispatch(ReportContentColumnUpdateAction({ data: ReportContentColumn, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(ReportContentColumnErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const editReportTemplate = (templateId: string, editReportTemplatePostRequest: CreateReportData) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(editReportTemplateLoadingAction())

  void EditReportTemplateService.editReportTemplate(
    templateId,
    editReportTemplatePostRequest,
    (httpStatus: number) => {
      dispatch(editReportTemplateUpdateAction(httpStatus))
    },
    (errorMessage, httpStatus) => {
      dispatch(editReportTemplateErrorAction({ errorMessage, httpStatus }))
    }
  )
}

export const deleteReportTemplate = (templateId: string) => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(deleteReportTemplateLoadingAction())

  void DeleteReportTemplateService.deleteReportTemplate(
    templateId,
    (httpStatus) => {
      dispatch(deleteReportTemplateUpdateAction(httpStatus))
    },
    (httpStatus) => {
      dispatch(deleteReportTemplateErrorAction(httpStatus))
    }
  )
}