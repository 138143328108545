/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as AssetByIdService from '../services/assetByIdService'
import * as AlarmByIdService from '../services/alarmsByIdService'
import { assetByIdDataLoading, assetByIdDataError, addAssetByIdData, type Asset } from './assetByIdSlice'
import { getAllTagsByIdDataLoading, getAllTagsByIdDataError, getAllTagsByIdData } from './getAllTagsByIdSlice'
import { addChannelData, channelDataLoading, channelDataError } from './channelDataSlice'
import { addRegTableData, RegTableDataLoading, RegTableDataError } from './registerReadingsSlice'
import * as IntervalReadingsService from '../services/intervalReadingsService'
import * as RegReadingsService from '../services/regReadTableService'
import { getCellularOptions, getConsumptionDataOptions } from '../components/dataSourceComponents/ChartOptions'
import { deviceListErrorAction, deviceListLoadingAction, deviceListUpdateAction } from './deviceListSlice'
import * as DeviceListService from '../services/deviceListService'
import { alarmByIdDataLoading, alarmByIdDataError, addAlarmByIdData, type Alarm } from './alarmsByIdSlice'
import { signalDataLoadingAction, signalDataUpdateAction, signalDataErrorAction, rawDataUpdateAction } from './cellularSignalSlice'
import * as cellularSignalService from '../services/cellularSignalService'
import * as ChannelDropdownService from '../services/channelDropDownService'
import { channelDropdownLoading, addChannelDropdownData, channelDropdownError } from './channelDropdownSlice'
import { EditAssetSummaryInfoDataLoading, EditAssetSummaryInfoDataError, EditAssetSummaryInfoData, ResetEditAssetSummaryParamsData } from './editAssetSummaryInfoSlice'
import * as EditAssetSummaryService from '../services/editAssetSummaryService'
import { type editSummaryReq } from '../services/editAssetSummaryService'
import * as getAllTagsByIdService from '../services/getAllTagsById'
import { deviceHistoryErrorAction, deviceHistoryLoadingAction, deviceHistoryUpdateAction } from './deviceLifeCycleHistorySlice'
import * as DeviceHistoryService from '../services/deviceLifeCycleHistoryService'
import { getAlarmHistory } from '../services/alarmHistoryService'
import { type cellularSignal, type transmissionSignal, type signalQuality } from '../services/cellularSignalService'
import { alarmHistoryLoadingAction, alarmHistoryUpdateAction, alarmHistoryErrorAction } from './alarmHistorySlice'

export const editAssetSummaryInfo = (editSummaryParamter: editSummaryReq, assetId: string, tenantId: string) => async (dispatch: any) => {
  dispatch(getAllTagsByIdDataLoading())
  void EditAssetSummaryService.editAssetSummary(editSummaryParamter, assetId, tenantId, (message: string, httpStatus: number) => {
    dispatch(EditAssetSummaryInfoData({ message, httpStatus }))
  },
  // istanbul ignore next
  (message: string, httpStatus: number) => {
    dispatch(EditAssetSummaryInfoDataError({ message, httpStatus }))
  })
}

export const getAllTagsByIDInfo = (search: string) => async (dispatch: any) => {
  dispatch(EditAssetSummaryInfoDataLoading())
  void getAllTagsByIdService.getAllTagsById(search, (data: any, httpStatus: number) => {
    dispatch(getAllTagsByIdData({ data, httpStatus }))
  },
  (errorMessage: string, httpStatus: number) => {
    dispatch(getAllTagsByIdDataError({ errorMessage, httpStatus }))
  })
}

export const resetEditAssetSummaryParamsData = () => async (dispatch: any) => {
  dispatch(ResetEditAssetSummaryParamsData())
}

const parseAssetData = (assetData: Asset): Asset => {
  const remBatPercent = assetData.rem_bat_percent !== null ? parseFloat(assetData.rem_bat_percent.toFixed(2)) : assetData.rem_bat_percent

  return {
    id: assetData.id,
    name: assetData.name,
    serial_number: assetData.serial_number,
    device_type: assetData.device_type,
    comm_type: assetData.comm_type,
    rem_bat_percent: remBatPercent,
    comm_mod_serial: assetData.comm_mod_serial,
    asset_state: assetData.asset_state,
    latitude: assetData.latitude,
    longitude: assetData.longitude,
    install_date: assetData.install_date,
    firmware_version: assetData.firmware_version,
    removal_date: assetData.removal_date,
    ip_address: assetData.ip_address,
    port: assetData.port,
    tag: assetData.tag
  }
}

const parseChannelDropdownData = (ChannelDropdownData: any): any => {
  const parsedChannels = ChannelDropdownData.channels.map((channel: any) => ({
    readingTypeName: channel.cimCode.readingTypeName,
    units: channel.cimCode.units,
    description: channel.cimCode.description,
    channelId: channel.channelId,
    channelName: channel.channelName
  }))
  return parsedChannels
}

const parseAlarmData = (alarmData: AlarmByIdService.AlarmData): Alarm[] => {
  const result = alarmData.alarmDetails.map((alarm) => {
    return {
      alarmDefinitionId: alarm.alarmDefinitionId,
      alarmName: alarm.alarmName,
      severity: alarm.severity,
      alarmStatus: alarm.alarmStatus,
      readingTime: alarm.readingTime
    }
  })
  return result
}

export const fetchAssetData = (
  id: string,
  tenantId: string,
  loading = true
) => (dispatch: any) => {
  if (loading) {
    dispatch(assetByIdDataLoading())
  }
  void AssetByIdService.getAssetById(
    id,
    tenantId,
    (assetData, httpStatus) => {
      const parsedData = parseAssetData(assetData)
      dispatch(addAssetByIdData({ data: parsedData, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(assetByIdDataError({ errorMessage, httpStatus }))
    }
  )
}

const parseChartSeriesData = (data: IntervalReadingsService.IntervalReading): number[][] => {
  const sortedData = data.assetReadingsData.sort((item1, item2) => (new Date(item1.readingTime).getTime() - new Date(item2.readingTime).getTime()))
  const res = sortedData.map((arrElem: any) => [(new Date(arrElem.readingTime)).getTime(), arrElem.value, arrElem.indexValue])
  return res
}

export const fetchChannelData = (assetId: string, fromDate: string, toDate: string, selectedChannel: string, selectedChannelUnit: string, tenantId: string) => (dispatch: any) => {
  dispatch(channelDataLoading())
  void IntervalReadingsService.getIntervalReadings(
    assetId,
    fromDate,
    toDate,
    selectedChannel,
    tenantId,
    (intervalReadings, httpStatus) => {
      const unit = selectedChannelUnit !== undefined ? selectedChannelUnit : ''
      const consumptionOptions = getConsumptionDataOptions(
        parseChartSeriesData(intervalReadings), unit
      )
      const channelDataOptions = {
        consumptionChartOptions: consumptionOptions
      }

      dispatch(addChannelData({ data: channelDataOptions, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(channelDataError({ errorMessage, httpStatus }))
    }
  )
}

export const fetchChannelDropdownData = (assetId: string, tenantId: string) => (dispatch: any) => {
  dispatch(channelDropdownLoading())

  void ChannelDropdownService.getChannelDropdownData(
    assetId,
    tenantId,
    (data) => {
      const parsedData = parseChannelDropdownData(data)
      dispatch(addChannelDropdownData(parsedData))
    },
    (errorMessage) => {
      dispatch(channelDropdownError(errorMessage))
    }
  )
}

export const fetchDeviceList = (page: any, size: any, sortdir: any, sortfield: any) => async (dispatch: any): Promise<void> => {
  dispatch(deviceListLoadingAction())

  void DeviceListService.getDeviceList(
    page,
    size,
    sortdir,
    sortfield,
    (deviceList) => {
      dispatch(deviceListUpdateAction({ data: deviceList.assets, count: deviceList.total_count }))
    },
    (errorMessage) => {
      dispatch(deviceListErrorAction(errorMessage))
    }
  )
}

const parseRegTableData = (data: any): any => {
  const res = data.assetReadingsData.map((arrElem: any, index: number) => {
    return {
      id: index + 1,
      register: arrElem.registerName,
      measurementPeriod: arrElem.readingTime,
      eventType: '--',
      collected: arrElem.value,
      deltaValue: '--',
      lastUpdated: null,
      calculatedValue: arrElem.calculatedValue
    }
  })
  return res
}

export const fetchRegReadTableData = (assetId: string, page: string, size: string, tenantId: string, sortdir: string, sortfield: string) => (dispatch: any) => {
  dispatch(RegTableDataLoading())

  void RegReadingsService.getIntervalReadings(
    assetId,
    page,
    size,
    tenantId,
    sortdir,
    sortfield,
    (intervalReadings, httpStatus) => {
      const ParsedRegTableData = parseRegTableData(intervalReadings)
      dispatch(addRegTableData({ data: ParsedRegTableData, count: intervalReadings.total_count, httpStatus } as any))
    },
    (errorMessage, httpStatus) => {
      dispatch(RegTableDataError({ errorMessage, httpStatus } as any))
    }
  )
}

export const fetchAlarmsById = (
  id: string,
  tenantId: string
) => (dispatch: any) => {
  dispatch(alarmByIdDataLoading())

  void AlarmByIdService.getAlarmsById(
    id,
    tenantId,
    // istanbul ignore next
    (alarmData: AlarmByIdService.AlarmData, httpStatus) => {
      const parsedData = parseAlarmData(alarmData)
      dispatch(addAlarmByIdData({ AlarmData: parsedData, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(alarmByIdDataError({ errorMessage, httpStatus }))
    }
  )
}

// istanbul ignore next
function parseData (jsonData: any) {
  const kpiDefinitionId = []
  const timestamps = []
  const kpiValues = []

  for (const entry of jsonData) {
    kpiDefinitionId.push(entry.kpi_definition_id)
    timestamps.push(entry.timestamp)
    kpiValues.push(entry.kpi_value)
  }
  return { kpiDefinitionId, timestamps, kpiValues }
}

function formatDate (inputDate: any) {
  const date = new Date(inputDate)
  const year = date.getUTCFullYear()
  const month = String(date.getUTCMonth() + 1).padStart(2, '0')
  const day = String(date.getUTCDate()).padStart(2, '0')
  const hours = String(date.getUTCHours()).padStart(2, '0')
  const minutes = String(date.getUTCMinutes()).padStart(2, '0')
  const seconds = String(date.getUTCSeconds()).padStart(2, '0')
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000+00:00`
  return formattedDate
}

function createArray (values: any, timestamps: any) {
  let temp = []
  const data = []
  for (let i = 0; i < values.length; i++) {
    temp.push(timestamps[i])
    temp.push(values[i])
    data.push(temp)
    temp = []
  }

  const formattedDate = data.map((item) => [new Date(formatDate(item[0])).getTime(), item[1]])
  const formattedData = formattedDate.sort((prev, next) => new Date(prev[0]).getTime() - new Date(next[0]).getTime())
  return (formattedData)
}

export const fetchSignalData =
  (tenant: string, assetId: string, from: string, to: string) => async (dispatch: any) => {
    dispatch(signalDataLoadingAction())
    void cellularSignalService.getSignalData(
      tenant,
      assetId,
      from,
      to,
      (cellularData: cellularSignal[], signalQualityData: signalQuality[], transmissionSignalData: transmissionSignal[], httpStatus) => {
        const parsedCellularData = parseData(cellularData)
        const cellularArrayData = createArray(parsedCellularData.kpiValues, parsedCellularData.timestamps)
        const parsedSignalQualityData = parseData(signalQualityData)
        const signalQualityArrayData = createArray(parsedSignalQualityData.kpiValues, parsedSignalQualityData.timestamps)
        const parsedTransmissionSignalData = parseData(transmissionSignalData)
        const transmissionArrayData = createArray(parsedTransmissionSignalData.kpiValues, parsedTransmissionSignalData.timestamps)
        const chartArrayData = getCellularOptions(cellularArrayData, signalQualityArrayData, transmissionArrayData)
        const channelDataOptions = {
          consumptionChartOptions: chartArrayData
        }
        dispatch(signalDataUpdateAction({ data: channelDataOptions, httpStatus }))
        if (cellularData.length === 0 && signalQualityData.length === 0 && transmissionSignalData.length === 0) {
          dispatch(rawDataUpdateAction({ raw: true, httpStatus }))
        }
      },
      (errorMessage, httpStatus) => {
        dispatch(signalDataErrorAction({ errorMessage, httpStatus }))
      }
    )
  }

export const fetchDeviceHistory = (assetId: string, tenantId: string) => async (dispatch: any): Promise<void> => {
  dispatch(deviceHistoryLoadingAction())

  void DeviceHistoryService.getDeviceHistory(
    assetId,
    tenantId,
    (deviceHistory) => {
      dispatch(deviceHistoryUpdateAction({ data: deviceHistory.state_details, count: deviceHistory.total_count }))
    },
    (errorMessage) => {
      dispatch(deviceHistoryErrorAction(errorMessage))
    }
  )
}

export const fetchAlarmHistory = (assetId: string, page: number, size: number) => async (dispatch: any): Promise<void> => {
  dispatch(alarmHistoryLoadingAction())
  void getAlarmHistory(
    assetId,
    page,
    size,
    (alarmHistoryData, httpStatus) => {
      dispatch(alarmHistoryUpdateAction({ data: alarmHistoryData, httpStatus }))
    },
    (errorMessage, httpStatus) => {
      dispatch(alarmHistoryErrorAction({ errorMessage, httpStatus }))
    }
  )
}
