import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const Zprotocols = z.object({
  protocolTypeId: z.string(),
  protocolTypeName: z.string()
})

const ZData = z.object({
  protocols: Zprotocols.array()
})

const ZApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: ZData
})

export type ConfigurationMetadataDetails = z.infer<typeof ZApiResponse>
export type ConfigurationMetadata = z.infer<typeof ZData>

export async function getConfigurationMetadata (
  onSuccess: (ConfigurationMetadata: ConfigurationMetadata, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = '/app-config-service/api/v1/udp/configure-protocol/metadata'
  interceptedAxios
    .get(url, { timeout: 10000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (response.data.data !== null && response.data.data !== undefined) {
            onSuccess(parseResponse.data.data, response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message, error.status)
    })
}
