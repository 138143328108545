import { Card, CardContent, CircularProgress, Grid, Button, Box, Tab } from '@mui/material'
import { type AssetByIdState } from '../redux/assetByIdSlice'
import AssetSummary from './AssetSummary'
import AlarmHistory from './alarmHistory'
import React from 'react'
import ActiveIssuesTable from './ActiveIssues'
import { type AlarmByIdState } from '../redux/alarmsByIdSlice'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { useNavigate } from 'react-router-dom'
import { type RootState } from '../../../store'
import { type OperationPermissionState } from '../../dashboard/redux/operationPermissionSlice'
import { useSelector } from 'react-redux'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

const Overview = (props: { handleFetchAssetData: () => void, fetchAlarmsById: () => void, assetData: AssetByIdState, OverviewStrings: any, alarmsById: AlarmByIdState }): JSX.Element => {
  const navigate = useNavigate()
  const [value, setValue] = React.useState('1')

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  const operationPermissions: OperationPermissionState = useSelector((state: RootState) => state.operationPermissions)
  const handleRetry = (): void => {
    props.fetchAlarmsById()
  }

  function AlarmTable (): any {
    if (props.alarmsById.isLoading) {
      return (
        <div className='deviceDetailsProgress'><CircularProgress data-testid='deviceDetailsProgress' size={'5rem'} /></div>)
    } else if (props.alarmsById.httpStatus === 401) {
      return (
        <div className='authError'><AuthError errorMessage="Unauthorized" /></div>
      )
    } else if (props.alarmsById.httpStatus === 403) {
      return (
        <div className='authError'><AuthError errorMessage="accessForbidden" /></div>
      )
    } else if (props.alarmsById.alarmData !== null && props.alarmsById.alarmData !== undefined && props.alarmsById.httpStatus === 200) {
      return (
        <ActiveIssuesTable alarmsById={props.alarmsById} OverviewStrings={props.OverviewStrings} />)
    } else if ((props.alarmsById.alarmData !== null && props.alarmsById.alarmData !== undefined && props.alarmsById.alarmData.length === 0)) {
      return (
        <div className='authError'><AuthError errorMessage="NoDataPresent" /></div>
      )
    } else if (((props.alarmsById.errorMessage?.includes('not found')) === true)) {
      return (
        <div className='authError'><AuthError errorMessage="NoDataPresent" /></div>
      )
    } else {
      return (
        <div className='authError'><AuthError retry={handleRetry} errorMessage="cannotFetch" /></div>
      )
    }
  }

  function handleAssetSummary (): any {
    if (props.assetData.isLoading) {
      return (
        <div className='deviceDetailsProgress'><CircularProgress data-testid='deviceDetailsProgress' size={'5rem'} /></div>)
    } else if (props.assetData.httpStatus === 401) {
      return (
        <div className='authError'><AuthError errorMessage="Unauthorized" /></div>
      )
    } else if (props.assetData.httpStatus === 403) {
      return (
        <div className='authError'><AuthError errorMessage="accessForbidden" /></div>
      )
    } else if (props.assetData.assetData !== null && props.assetData.assetData !== undefined && props.assetData.httpStatus === 200) {
      return (
        <AssetSummary assetData={props.assetData} OverviewStrings={props.OverviewStrings} />)
    } else {
      return (
        <div className='authError'><AuthError retry={props.handleFetchAssetData} errorMessage="cannotFetch" /></div>
      )
    }
  }

  return (
    <div>
     <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab sx={{ color: 'gray' }} label="ACTIVE ISSUE" value="1" />
            <Tab sx={{ color: 'gray' }} label="ALARM HISTORY" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
        <Grid item xs={12} sm={12} className='active-issues'>
        <h4 style={{ fontSize: '12px' }}>{props.OverviewStrings.ACTIVE_ISSUES}({props.alarmsById.alarmData?.length})</h4>
        <Card sx={{ borderRadius: '10px', backgroundColor: '#353535' }}>
          <CardContent sx={{ padding: '0' }}>
            {AlarmTable()}
          </CardContent>
        </Card>
      </Grid>
        </TabPanel>
        <TabPanel value="2">
          <AlarmHistory />
        </TabPanel>
      </TabContext>
    </Box>
      <br />
      <Grid item xs={12} sm={12}>
        <Card sx={{ borderRadius: '10px', backgroundColor: '#272727', color: '#F0F0F0 !important', padding: '25px' }}>
          <h4>{props.OverviewStrings.ASSET_SUMMARY}</h4>
          {operationPermissions.permissions.can_modify_asset
            ? <Button data-testid='' className='btn-asset'
              onClick={() => { navigate('/edit-asset-summary') }}
              style={{
                border: '1px solid #32617f',
                textTransform: 'capitalize',
                padding: '5px 27px',
                backgroundColor: '#64c3ff',
                fontWeight: '700',
                float: 'right',
                marginTop: '-40px'
              }}
            >
              Edit
            </Button>
            : <></>
          }
          <CardContent>
            {handleAssetSummary()}
          </CardContent>
        </Card>
      </Grid>
    </div>
  )
}

export default Overview
