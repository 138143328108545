/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import {
  GoogleMap,
  InfoWindow,
  MarkerClustererF,
  MarkerF,
  useJsApiLoader
} from '@react-google-maps/api'
import { Link, useNavigate } from 'react-router-dom'
import lowBadge from '../../assets/lowBadge.svg'
import highBadge from '../../assets/maps/highBadge.png'
import mediumBadge from '../../assets/mediumBadge.svg'
import CriticalBadge from '../../assets/criticalBadge.svg'
import mapOptions from '../../assets/maps/mapOptions.json'
import { type ConnectedProps, connect, useDispatch } from 'react-redux'
import { changeAssetId } from '../device-details/redux/assetIdSlice'
import { fetchAllActiveAlarms } from '../ami-meter-data-collection/redux/actionCreators'
import { type AlarmDataState } from '../ami-meter-data-collection/redux/allAlarmsSlice'
import { type RootState } from '../../store'
import { InfoModalWithInfiniteLoading } from './infoPopup'
import { CircularProgress } from '@mui/material'
import { type MapDataState } from '../ami-meter-data-collection/redux/mapDataSlice'

interface MapProps {
  AMIMarkers: AMIMarkerData[] | []
}
export interface AMIMarkerData {
  assetName: string
  location: Location
  severity: string
  assetId: string
  alarmName: string
}

interface Location {
  lat: number | null
  lng: number | null
}

const containerStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '6px'
}

const options: google.maps.MapOptions = {
  styles: mapOptions
}

// istanbul ignore next
export const nortBadge = (severity: string): any => {
  switch (severity.toLowerCase()) {
    case 'low':
      return lowBadge
    case 'medium':
      return mediumBadge
    case 'high':
      return highBadge
    case 'critical':
      return CriticalBadge
    default:
      return lowBadge
  }
}

const MapComponent = (props: AllProps): any => {
  // istanbul ignore next
  console.log('rerendering map')
  const GoogleMapsApiKey =
    typeof window._env_?.REACT_APP_GOOGLE_MAPS_API_KEY === 'string'
      ? window._env_.REACT_APP_GOOGLE_MAPS_API_KEY
      : ''
  let centerLat = 0
  let centerLng = 0
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    nonce: 'randon-nonce',
    googleMapsApiKey: GoogleMapsApiKey
  })
  const [centerLocation, setCenterLocation] = useState<{
    lat: number
    lng: number
  }>({ lat: 27.988236190337403, lng: 86.92517183841176 })
  const [zoomLevel, setZoomLevel] = useState<number>(2)
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null)
  const [selectedMarkers, setSelectedMarkers] = useState<AMIMarkerData[]>([])
  const [markers, setMarkers] = useState(new Map<string, AMIMarkerData[]>())
  const [showInfoModal, setShowInfoModal] = useState<AMIMarkerData[]>([])
  const [markersLoaded, setMarkersLoaded] = useState<boolean>(false)
  const [map, setMap] = useState<google.maps.Map | null>(null)

  // istanbul ignore next
  const onLoad = React.useCallback(function callback (map: any) {
    const bounds = new window.google.maps.LatLngBounds(centerLocation)
    map.fitBounds(bounds)
    map.panTo(centerLocation)
    // set map zoom level
    setTimeout(() => {
      map.setZoom(zoomLevel)
    }, 1000)

    setMap(map)
  }, [])

  function onUnmount (): void {
    setMarkers(new Map<string, AMIMarkerData[]>())
    setMap(null)
  }

  useEffect(() => {
    // istanbul ignore else
    if (props.AMIMarkers.length > 0) {
      const groupedMarkersBasedOnLoation = new Map<string, AMIMarkerData[]>()
      props.AMIMarkers.forEach((asset: AMIMarkerData) => {
        if (asset.location.lat !== null && asset.location.lng !== null) {
          const key = `${asset.location.lat},${asset.location.lng}`
          if (!groupedMarkersBasedOnLoation.has(key)) {
            groupedMarkersBasedOnLoation.set(key, [asset])
          } else {
            groupedMarkersBasedOnLoation.get(key)?.push(asset)
          }
        }
      })

      setMarkers(groupedMarkersBasedOnLoation)

      const AmiMarkers: AMIMarkerData[] = [...props.AMIMarkers]

      // istanbul ignore next
      centerLat =
        AmiMarkers.reduce(
          (total: number, element: AMIMarkerData) =>
            Number(Number(total) + Number(element.location.lat ?? 0)),
          0
        ) / AmiMarkers.length
      // istanbul ignore next
      centerLng =
        AmiMarkers.reduce(
          (total: number, element: AMIMarkerData) =>
            Number(Number(total) + Number(element.location.lng ?? 0)),
          0
        ) / AmiMarkers.length

      setCenterLocation({ lat: centerLat, lng: centerLng })
      setZoomLevel(zoomLevel)
      map?.panTo(centerLocation)
    }
  }, [props.AMIMarkers])

  // istanbul ignore next
  const handleDeviceDetailClick = (
    assetId: string,
    assetName: string
  ): void => {
    setShowInfoModal([])
    onUnmount()
    dispatch(changeAssetId(assetId))
    sessionStorage.setItem(
      'paramAssetId',
      assetId != null ? assetId.toString() : ''
    )
    sessionStorage.setItem(
      'paramAssetName',
      assetName != null ? assetName.toString() : ''
    )
    navigate('/devicedetails')
  }

  // istanbul ignore next
  const renderMarkers = (): JSX.Element => {
    const abbreviate = (n: number): string => {
      if (n < 1e3) return n.toString()
      if (n >= 1e3 && n < 1e6) return `${+(n / 1e3).toFixed(1)}K`
      if (n >= 1e6 && n < 1e9) return `${+(n / 1e6).toFixed(1)}M`
      if (n >= 1e9 && n < 1e12) return `${+(n / 1e9).toFixed(1)}B`
      return `${+(n / 1e12).toFixed(1)}T`
    }
    const seperateMarkers = Array.from(markers.keys())
    return (
      <MarkerClustererF
        zoomOnClick={true}
        options={{
          gridSize: 80
        }}
      >
        {
          // istanbul ignore next
          (clusterer: any) => (
            <div>
              {seperateMarkers.map((obj: string, i) => {
                if (i === seperateMarkers.length - 1) {
                  setMarkersLoaded(true)
                }
                const lat = Number(obj.split(',')[0])
                const lng = Number(obj.split(',')[1])
                const numOfMarkers = Number(markers?.get(obj)?.length)
                if (lat !== null && lng !== null) {
                  return (
                    <MarkerF
                      noClustererRedraw={true}
                      key={`${obj}`}
                      label={(numOfMarkers > 1) ? { text: abbreviate(numOfMarkers), color: '#f0f0f0', fontSize: '10px' } : ''}
                      position={{
                        lat, lng
                      }}
                      onClick={() => {
                        // istanbul ignore next
                        setSelectedLocation(obj)
                        setSelectedMarkers(markers.get(obj) ?? [])
                        setCenterLocation({
                          lat: lat ?? 27.988236190337403,
                          lng: lng ?? 86.92517183841176
                        })
                      }}
                      clusterer={clusterer}
                    />
                  )
                }
                // istanbul ignore next
                return undefined
              })}
            </div>
          )
        }
      </MarkerClustererF>
    )
  }

  const renderMarkersMemoized = React.useMemo(() => renderMarkers(), [markers])

  // istanbul ignore next
  function getInfoBar (assetData: AMIMarkerData[]): JSX.Element {
    const itemsToShow = assetData.length > 3 ? assetData.slice(0, 3) : assetData
    return (
        <>
          <ul style={{ margin: 0, padding: 0 }}>
            {itemsToShow.map((asset, index) => {
              return (
                <li
                  style={{
                    listStyleType: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    padding: 5,
                    borderBottom: index < 2 ? '1px solid #e0e0e0' : 'none'
                  }}
                  key={`${asset.assetId} - ${index}`}
                >
                  <div id="popupDivAmi">
                    <div>
                      Asset Name: <span>{asset.assetName}</span>
                    </div>
                    <div>
                      Alarm Name: <span>{asset.alarmName}</span>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        style={{
                          paddingTop: 5,
                          paddingBottom: 5,
                          paddingRight: 10
                        }}
                        src={nortBadge(asset.severity.toLowerCase())}
                      />
                      <a
                        style={{ color: '#64c3ff', fontWeight: 600, cursor: 'pointer' }}
                        onClick={
                          // istanbul ignore next
                          () => {
                            handleDeviceDetailClick(
                              asset.assetId,
                              asset.assetName
                            )
                          }
                        }
                      >
                        View Details
                      </a>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
          {
            assetData.length > 3 &&
            <span
              onClick={() => {
                setShowInfoModal(markers.get(selectedLocation ?? '') ?? [])
              }}
              style={{ color: '#64c3ff', fontWeight: 600, cursor: 'pointer' }}
            >
              +{assetData.length - 3} more
            </span>
          }
        </>
    )
  }

  const getInfoBarMemoized = React.useMemo(() => getInfoBar(selectedMarkers), [selectedMarkers])

  // istanbul ignore next
  if (isLoaded) {
    if (markers.size > 0) {
      return (
        <>
          <GoogleMap
            mapContainerStyle={containerStyle}
            onLoad={onLoad}
            onUnmount={onUnmount}
            center={centerLocation}
            zoom={zoomLevel}
            onZoomChanged={
              // istanbul ignore next
              () => {
                const newZoomLevel = map?.getZoom()
                if (newZoomLevel !== undefined) {
                  setZoomLevel(newZoomLevel)
                }
              }
            }
            options={options}
            id={'map'}
          >
            {renderMarkersMemoized}
            {
              // istanbul ignore next
              !markersLoaded && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                >
                  <CircularProgress />
                </div>
              )
            }
            {
              // istanbul ignore next
              selectedLocation != null && selectedMarkers.length === 1 && (
                // istanbul ignore next
                <InfoWindow
                  onCloseClick={
                    // istanbul ignore next
                    () => {
                      setSelectedLocation(null)
                    }
                  }
                  position={{
                    lat: Number(selectedLocation.split(',')[0]) + 0.00001,
                    lng: Number(selectedLocation.split(',')[1])
                  }}
                >
                  <>
                    <div id="popupDivAmi">
                      <div>
                        Asset Name: <span>{markers.get(selectedLocation)?.at(0)?.assetName}</span>
                      </div>
                      <div>
                        Alarm Name: <span>{markers.get(selectedLocation)?.at(0)?.alarmName}</span>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                          style={{
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingRight: 10
                          }}
                          src={nortBadge(markers.get(selectedLocation)?.at(0)?.severity ?? '')}
                        />
                        <Link
                          style={{ color: '#64c3ff', fontWeight: 600 }}
                          to={'/devicedetails'}
                          onClick={
                            // istanbul ignore next
                            () => {
                              handleDeviceDetailClick(
                                markers.get(selectedLocation)?.at(0)?.assetId ?? '',
                                markers.get(selectedLocation)?.at(0)?.assetName ?? ''
                              )
                            }
                          }
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                  </>
                </InfoWindow>
              )
            }
            {selectedLocation !== null && selectedMarkers.length > 1 && (
              <InfoWindow
                onCloseClick={
                  // istanbul ignore next
                  () => {
                    setSelectedLocation(null)
                  }
                }
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                position={{ lat: Number(selectedLocation.split(',')[0]), lng: Number(selectedLocation.split(',')[1]) }}
              >
                {getInfoBarMemoized}
              </InfoWindow>
            )}
          </GoogleMap>
          {
            // istanbul ignore next
            showInfoModal.length > 0
              ? <InfoModalWithInfiniteLoading
                    markerData={showInfoModal}
                    handleModalClose={() => {
                      setShowInfoModal([])
                    }}
                    handleDeviceDetailClick={handleDeviceDetailClick}
                />
              : (
              <></>
                )
          }
        </>
      )
    } else if (props.mapData.isLoading) {
      return (
        <div data-testid='map-loading-element' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
        </div>
      )
    } else {
      return (
        <>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={{ lat: 27.988236190337403, lng: 86.92517183841176 }}
            zoom={zoomLevel}
            options={options}
            id={'map'}
          ></GoogleMap>
        </>
      )
    }
  } else {
    return (
      <div data-testid='map-loading-element' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
      </div>
    )
  }
}

interface DispatchToProps {
  fetchAllActiveIssues: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    tenantId: string
  ) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchAllActiveIssues: (
    page: number,
    size: number,
    sortdir: string,
    sortfield: string,
    tenantId: string
  ) => dispatch(fetchAllActiveAlarms(page, size, sortdir, sortfield, tenantId))
})

interface StateToProps {
  allActiveIssues: AlarmDataState
  mapData: MapDataState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  allActiveIssues: state.allActiveIssues,
  mapData: state.mapData
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>
type AllProps = MapProps & PropsFromRedux

export default connector(MapComponent)
