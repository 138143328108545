import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import {
  Box,
  Breadcrumbs,
  CardContent,
  CircularProgress,
  Grid,
  Link,
  Typography
} from '@mui/material'
import LanguageStrings from '../../../../i18n/locales'
import React from 'react'
import { Button } from '../../../../components/Button'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../../store'
import { type ProtocolsDetailsState } from '../../redux/getProtocolDetailsByIdSlice'
import { getConfigurationListDetails, getProtocolDetailsById } from '../../redux/actionCreators'
import { type ConfigurationListDataState } from '../../redux/getConfigurationProtocolListSlice'
import { useNavigate } from 'react-router-dom'
import AuthError from '../../../../components/ErrorComponents/AuthError'

const CreateSFTPStrings =
  LanguageStrings().AMIDataCollectionStrings.ConfigurationUploadStrings
    .SchedulerStrings.CreateSFTPFormStrings

const style = {
  width: '99%',
  borderRadius: '10px',
  border: '1px solid #101010',
  background: '#272727',
  boxShadow: '1px 1px 1px 0px rgba(0, 0, 0, 0.15)',
  fontSize: '16px',
  marginBottom: '16px',
  padding: '24px'
}

const ConfigurationProtocol = (props: PropsFromRedux): JSX.Element => {
  const navigate = useNavigate()
  const configurationList = useSelector(
    (state: RootState) => state.ConfigurationListData.ConfigurationListData
  )
  const configurationListStatus = useSelector(
    (state: RootState) => state.ConfigurationListData
  )

  React.useEffect(() => {
    props.getConfigurationListDetails('createdAt', 'desc')
  }, [])

  const handleFetchSchedulerList = (): void => {
    props.getConfigurationListDetails('createdAt', 'desc')
  }

  const createConfiguration = (): void => {
    navigate('/create-sftp-configuration')
  }

  const handleEditClick = (): void => {
    sessionStorage.setItem('protocolId', configurationList != null ? configurationList[0]?.protocolId.toString() : '')
    navigate('/edit-configuration-protocol')
  }

  const handleConfigurationDetails = (): JSX.Element => {
    if (configurationList !== undefined && configurationListStatus.httpStatus === 200) {
      return (
    <>
    <Box sx={style} className="add-Scheduler-container">
    <Typography
      color="inherit"
      sx={{
        fontSize: '16px',
        textTransform: 'uppercase',
        fontWeight: '800',
        fontFamily: 'Honeywell Sans Web'
      }}
    >
      {CreateSFTPStrings.configurationSpecifications}
    </Typography>
    <CardContent>
      <Grid container spacing={1}>
        <Grid item md={3}>
          <span style={{ fontSize: '15px', padding: 5, display: 'block' }}>
            {CreateSFTPStrings.name}
          </span>
          <span style={{ fontSize: '14px', padding: 5, display: 'block' }}>
            {configurationList !== undefined
              ? configurationList[0]?.connectionName
              : 'NA'}
          </span>
        </Grid>
        <Grid item md={3}>
          <span style={{ fontSize: '15px', padding: 5, display: 'block' }}>
            {CreateSFTPStrings.description}
          </span>
          <span style={{ fontSize: '14px', padding: 5, display: 'block' }}>
            {configurationList !== undefined
              ? configurationList[0]?.description
              : 'NA'}
          </span>
        </Grid>
      </Grid>
    </CardContent>
  </Box><Box sx={style} className="add-Scheduler-container">
      <Typography
        color="inherit"
        sx={{
          fontSize: '16px',
          textTransform: 'uppercase',
          fontWeight: '800',
          fontFamily: 'Honeywell Sans Web'
        }}
      >
        {CreateSFTPStrings.connectionSettings}
      </Typography>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item md={3}>
            <span style={{ fontSize: '15px', padding: 5, display: 'block' }}>
              {CreateSFTPStrings.protocol}
            </span>
            <span style={{ fontSize: '14px', padding: 5, display: 'block' }}>
              {configurationList !== undefined
                ? configurationList[0]?.protocolTypeName
                : 'NA'}
            </span>
          </Grid>
          <Grid item md={3}>
            <span style={{ fontSize: '15px', padding: 5, display: 'block' }}>
              {CreateSFTPStrings.protocolName}
            </span>
            <span style={{ fontSize: '14px', padding: 5, display: 'block' }}>
              {configurationList !== undefined
                ? configurationList[0]?.protocolName
                : 'NA'}
            </span>
          </Grid>
          <Grid item md={3}>
            <span style={{ fontSize: '15px', padding: 5, display: 'block' }}>
              {CreateSFTPStrings.SFTPIPAddress}
            </span>
            <span style={{ fontSize: '14px', padding: 5, display: 'block' }}>
              {configurationList !== undefined
                ? configurationList[0]?.ipAddress
                : 'NA'}
            </span>
          </Grid>
          <Grid item md={3}>
            <span style={{ fontSize: '15px', padding: 5, display: 'block' }}>
              {CreateSFTPStrings.SFTPPort}
            </span>
            <span style={{ fontSize: '14px', padding: 5, display: 'block' }}>
              {configurationList !== undefined
                ? configurationList[0]?.port
                : 'NA'}
            </span>
          </Grid>
        </Grid>
      </CardContent>
    </Box>
    <Box sx={style} className="add-configuration-container">
      <Grid container spacing={1}>
        <Grid style={{ textAlign: 'right' }} item md={12}>
          <Button
            data-testid="edit-btn"
            onClick={() => { handleEditClick() }}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
    </Box>
    </>
      )
    } else if (configurationListStatus.isLoading) {
      return (
        <div className="CircularprogressAmi">
          <CircularProgress />
        </div>
      )
    } else if (configurationListStatus.httpStatus === 401) {
      return (
        <div className="authError">
          <AuthError errorMessage="Unauthorized" />
        </div>
      )
    } else if (configurationListStatus.httpStatus === 403) {
      return (
        <div className="authError">
          <AuthError errorMessage="accessForbidden" />
        </div>
      )
    } else if (configurationListStatus.httpStatus === 404) {
      return (
        <div className="authError">
          <AuthError
          errorMessage="NoDataPresentCampaign"
          retry={createConfiguration}
          />
        </div>
      )
    } else {
      return (
        <div className="authError">
          <AuthError
            errorMessage="cannotFetch"
            retry={handleFetchSchedulerList}
          />
        </div>
      )
    }
  }

  return (
    <>
      <div
        role="presentation"
        data-testid="breadcrumbs"
        style={{ width: '100%' }}
      >
        <Breadcrumbs
          aria-name="breadcrumb"
          sx={{
            color: '#f0f0f0',
            fontSize: '12px',
            marginBottom: '14px',
            fontFamily: 'Honeywell Sans Web',
            marginTop: '12px'
          }}
        >
          <Link
            underline="hover"
            color="inherit"
            data-testid="scheduler-breadcrumb"
            onClick={() => {
              navigate('/configuration-dashboard')
            }}
          >
            {CreateSFTPStrings.dataPublisherSettings}
          </Link>
          <Typography
            color="inherit"
            sx={{ fontSize: '12px', fontFamily: 'Honeywell Sans Web' }}
          >
            {CreateSFTPStrings.configurationProtocol}
          </Typography>
        </Breadcrumbs>
      </div>
      <div style={{ display: 'flex' }}>
        <ArrowBackIos
          sx={{ cursor: 'pointer' }}
          data-testid="back-arrow"
            onClick={() => {
              navigate('/configuration-dashboard')
            }}
        />
        <div style={{ marginBottom: '32px' }}>
          <span
            data-testid="schedule-title"
            style={{
              color: '#f0f0f0',
              fontSize: '24px',
              fontWeight: '900',
              paddingRight: '16px',
              marginRight: '10px'
            }}
          >
            {CreateSFTPStrings.configurationProtocol}
          </span>
        </div>
      </div>
      {handleConfigurationDetails()}
    </>
  )
}

interface DispatchToProps {
  getConfigurationListDetails: (sortdir: string, sortfield: string) => void
  getProtocolDetailsById: (protocolId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  getConfigurationListDetails: (sortdir: string, sortfield: string) =>
    dispatch(getConfigurationListDetails(sortdir, sortfield)),
  getProtocolDetailsById: (protocolId: string) =>
    dispatch(getProtocolDetailsById(protocolId))
})

interface StateToProps {
  ConfigurationListData: ConfigurationListDataState
  ProtocolDetailsData: ProtocolsDetailsState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  ConfigurationListData: state.ConfigurationListData,
  ProtocolDetailsData: state.ProtocolDetailsData
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(ConfigurationProtocol)
