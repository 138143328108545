import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type Options } from 'highcharts'
import * as ChartOptions from '../components/dataSourceComponents/ChartOptions'

interface ChannelDataOptions {
  consumptionChartOptions: Options
}

export interface ChannelDataState {
  channelDataOptions: ChannelDataOptions | null
  isLoading: boolean
  errorMessage: string | null
  httpStatus: number | null
}

const initialState: ChannelDataState = {
  channelDataOptions: {
    consumptionChartOptions: ChartOptions.getConsumptionDataOptions()
  },
  isLoading: false,
  errorMessage: null,
  httpStatus: null
}

export const channelDataSlice = createSlice({
  name: 'channelDataDeviceDetail',
  initialState,
  reducers: {
    addChannelData: (state, action: PayloadAction<{ data: ChannelDataOptions, httpStatus: number }>) => {
      return {
        channelDataOptions: action.payload.data,
        isLoading: false,
        errorMessage: null,
        httpStatus: action.payload.httpStatus
      }
    },
    channelDataLoading: (state) => {
      return {
        channelDataOptions: null,
        isLoading: true,
        errorMessage: null,
        httpStatus: 0
      }
    },
    channelDataError: (state, action: PayloadAction<{ errorMessage: string, httpStatus: number }>) => {
      return {
        channelDataOptions: null,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
        httpStatus: action.payload.httpStatus
      }
    }
  }
})

export const { addChannelData, channelDataLoading, channelDataError } = channelDataSlice.actions
export default channelDataSlice.reducer
