/* eslint-disable @typescript-eslint/no-extraneous-class */
/* eslint-disable quote-props */

export default class DeviceUtil {
  static deviceTypes = Object.freeze({
    'A3':
            Object.freeze({
              type: 'A3',
              style: 200,
              isElectric: true,
              hasCommRate: false,
              hasMaxLevel: false,
              hasPowerStatusCheck: true,
              hasPresentValues: true
            }),
    'A3-SynegyNet':
            Object.freeze({
              type: 'A3_SYNERGYNET',
              style: 1200,
              isElectric: true,
              hasCommRate: true,
              hasMaxLevel: true,
              isSynergyNet: true,
              hasPowerStatusCheck: true,
              hasPresentValues: true,
              hasPing: true
            }),
    'AS300N-GPRS':
            Object.freeze({
              type: 'AS300N_GPRS',
              style: 1601,
              isElectric: true,
              hasPowerStatusCheck: true,
              hasPresentValues: true
            }),
    'AS300N-SynergyNet':
            Object.freeze({
              type: 'AS300N_SYNERGYNET',
              style: 1549,
              isElectric: true,
              isSynergyNet: true,
              hasPowerStatusCheck: true,
              hasPresentValues: true,
              hasPing: true
            }),
    'A4R-CELL':
            Object.freeze({
              type: 'A4_CELL',
              style: 1602,
              isElectric: true,
              hasPowerStatusCheck: true,
              hasPresentValues: true
            }),
    'A4CI-CELL':
            Object.freeze({
              type: 'A4CI_CELL',
              style: 1603,
              isElectric: true,
              hasPowerStatusCheck: true,
              hasPresentValues: true
            }),
    'A4R-SynergyNet':
            Object.freeze({
              type: 'A4R_SYNERGYNET',
              style: 1507,
              isElectric: true,
              isSynergyNet: true,
              hasPowerStatusCheck: true,
              hasPresentValues: true,
              hasPing: true
            }),
    'A4CI-SynergyNet':
            Object.freeze({
              type: 'A4CI_SYNERGYNET',
              style: 1508,
              isElectric: true,
              isSynergyNet: true,
              hasPowerStatusCheck: true,
              hasPresentValues: true,
              hasPing: true
            }),
    'EdgeControl-SynergyNet':
            Object.freeze({
              type: 'EDGECONTROL_SYNERGYNET',
              style: 1504,
              isElectric: true,
              isSynergyNet: true,
              hasPowerStatusCheck: true,
              hasPresentValues: true,
              hasPing: true
            }),
    'Gas-NXCM':
            Object.freeze({
              type: 'GASNXCM',
              style: 1702,
              isGas: true,
              isGasNxcm: true
            }),
    'Gas-NXS':
            Object.freeze({
              type: 'GASNXS',
              style: 1700,
              isGas: true
            }),
    'Gas-NXU':
            Object.freeze({
              type: 'GASNXU',
              style: 1701,
              isGas: true
            }),
    'GasNode-SynergyNet':
            Object.freeze({
              type: 'GAS_SYNERGYNET',
              style: 1505,
              isGas: true,
              isSynergyNet: true
            }),
    'Heat-Sensor':
            Object.freeze({
              name: 'HEAT_SENSOR',
              style: 1801
            }),
    'Methane-Sensor':
            Object.freeze({
              name: 'METHANE_SENSOR',
              style: 1800
            }),
    'REXU-SynergyNet':
            Object.freeze({
              name: 'REXU_SYNERGYNET',
              style: 1502,
              isElectric: true,
              isSynergyNet: true,
              hasPowerStatusCheck: true,
              hasPresentValues: true,
              hasPing: true
            }),
    'Router-SynergyNet':
            Object.freeze({
              name: 'ROUTER_SYNERGYNET',
              style: 1503,
              hasPing: true
            }),
    'Water-Merlin':
            Object.freeze({
              name: 'WATER_MERLIN',
              style: 1710,
              isWater: true
            }),
    'Water-NXCM':
            Object.freeze({
              name: 'WATERNXCM',
              style: 1703,
              isWater: true
            }),
    'WaterNode-SynergyNet':
            Object.freeze({
              name: 'WATER_SYNERGYNET',
              style: 1506,
              isWater: true,
              isSynergyNet: true
            }),
    'Water-Q600':
            Object.freeze({
              name: 'WATER_Q600',
              style: 1506,
              isWater: true,
              isQ600: true
            }),
    'Water-HBG3':
            Object.freeze({
              name: 'HBG3',
              style: 1506,
              isWater: true,
              isHBG3: true
            }),
    'Agua-Merlin':
            Object.freeze({
              name: 'AGUA_MERLIN',
              style: 1710,
              isWater: true
            }),
    'Agua-NXCM':
            Object.freeze({
              name: 'AGUANXCM',
              style: 1703,
              isWater: true
            }),
    'Agua-Q600':
            Object.freeze({
              name: 'AGUA_Q600',
              style: 1506,
              isWater: true,
              isQ600: true
            }),
    'Agua-HBG3':
            Object.freeze({
              name: 'HBG3',
              style: 1506,
              isWater: true,
              isHBG3: true
            }),
    'Water-V200H-NBIOT':
            Object.freeze({
              name: 'Water-V200H-NBIOT',
              style: 1506,
              isV200H: true,
              isWater: true
            })
  })

  static getDeviceTypeByName (typeName: string): any {
    // Test if key is present with "in". Need to do this to get around
    // restriction of Typescript that this method be called with a
    // type of "keyof typeof DeviceUtil.deviceTypes"
    if (typeName in DeviceUtil.deviceTypes) {
      return DeviceUtil.deviceTypes[typeName as keyof typeof DeviceUtil.deviceTypes]
    } else {
      return null
    }
  }

  static getDeviceTypeByMeterStyle (meterstyle: number): any {
    let k: keyof typeof DeviceUtil.deviceTypes
    for (k in DeviceUtil.deviceTypes) {
      if (DeviceUtil.deviceTypes[k].style === meterstyle) {
        return DeviceUtil.deviceTypes[k]
      }
    }
    return null
  }
}
