import interceptedAxios from '../../../utils/interceptedAxios'
import { z } from 'zod'

const ZCimCode = z.object({
  id: z.string(),
  cimCodeValue: z.string(),
  readingTypeName: z.string(),
  units: z.string(),
  description: z.string()
})

const ZChannelDropdownData = z.object({
  channelId: z.string(),
  channelName: z.string(),
  cimCodeId: z.string(),
  persistedInMDC: z.string(),
  cimCode: ZCimCode,
  assetId: z.string(),
  multiplier: z.number(),
  intervalLength: z.number(),
  lastReadingTime: z.string()
})

const ZmainData = z.object({
  assetId: z.string(),
  total_count: z.number(),
  channels: z.array(ZChannelDropdownData)
})

export type MainData = z.infer<typeof ZmainData>

export async function getChannelDropdownData (
  assetId: string,
  tenantId: string,
  onSuccess: (data: any) => any,
  onFailure: (errorMessage: string) => any
): Promise<void> {
  const url = `/asset-data-service/api/v1/udp/assets/data/channel?assetId=${assetId}&tenantId=${tenantId}`

  interceptedAxios.get(url, { timeout: 15000 })
    .then((response) => {
      // istanbul ignore else
      if (response.status === 200) {
        const parseResponse = ZmainData.safeParse(response.data.data)
        // istanbul ignore else
        if (parseResponse.success) {
          const parsedData: MainData = parseResponse.data
          onSuccess(parsedData)
        } else {
          onFailure(parseResponse.error.message)
        }
      }
    })
    .catch((error) => {
      onFailure(error.message)
    })
}
