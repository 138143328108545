/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import {
  Box,
  Breadcrumbs,
  CardContent,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
  styled
} from '@mui/material'
import React from 'react'
import LanguageStrings from '../../../../i18n/locales'
import { useNavigate } from 'react-router-dom'
import { GenXDropdown, type Option } from '../../../../components/GenXDropdown'
import { type ConnectedProps, connect, useSelector } from 'react-redux'
import { type RootState } from '../../../../store'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers'
import { Button } from '../../../../components/Button'
import dayjs, { type Dayjs } from 'dayjs'
import { useAuth } from 'react-oidc-context'
import './scheduler.css'
import Modal from '../../../../components/Modal'
import { fetchDeviceList, fetchAssetBySearchValue } from '../../../ami-meter-data-collection/redux/actionCreators'
import { fetchTemplateDetailsList, getAllCreateSchdulerDropdown, editReportSchedulerTemplate, getConfigurationListDetails, getReportSchedulerById } from '../../redux/actionCreators'
import { type EditReportSchedulerTemplateState } from '../../redux/editReportSchedulerSlice'
import { isValidName } from '../../../../utils/validator'
import { type EditReportSchedulerData } from '../../types'
import { type CreateSchedulerDropDownState } from '../../redux/getAllCreateSchdulerDropdownSlice'
import { fetchAssetGroupList } from '../../../asset-group/redux/actionCreator'
import { fetchAssetTypes } from '../../../asset-configuration/redux/actionCreator'
import { recurrencepattern, Totaldays, weekdays, MonthsInYear } from './schedulerData'
import AuthError from '../../../../components/ErrorComponents/AuthError'
import { type ConfigurationListDataState } from '../../redux/getConfigurationProtocolListSlice'
import { type ISchdulerDetailsState } from '../../redux/getReportSchedulerByIdSlice'

const CreateSchedulerStrings =
  LanguageStrings().AMIDataCollectionStrings.ConfigurationUploadStrings
    .SchedulerStrings.CreateSchedulerFormStrings

const style = {
  width: '99%',
  borderRadius: '10px',
  border: '1px solid #101010',
  background: '#272727',
  boxShadow: '1px 1px 1px 0px rgba(0, 0, 0, 0.15)',
  fontSize: '16px',
  marginBottom: '16px',
  padding: '24px'
}

const WhiteDatePicker = styled(DatePicker)({
  '& input': {
    color: '#b8b6b6',
    padding: '10px 14px'
  }
})

const WhiteTimePicker = styled(TimePicker)({
  '& input': {
    color: '#b8b6b6',
    padding: '10px 14px'
  }
})

const CustomLocalizationProviderWrapper = styled('div')({
  backgroundColor: '#5c5b5bdd',
  borderRadius: '4px',
  width: '86%'
})

const EditReportScheduler = (props: PropsFromRedux): JSX.Element => {
  const navigate = useNavigate()
  const [schedulerName, setSchedulerName] = React.useState<string | null>(null)
  const [selectedAssetType, setselectedAssetType] = React.useState<Option | null>(null)
  const [selectedAssetOptions, setselectedAssetOptions] = React.useState<Option[]>([])
  const [selectedGroupData, setGroupData] = React.useState<Option | null>(null)
  const [GroupDataOptions, setGroupDataOptions] = React.useState<Option[]>([])
  const [selectedAssetData, setAssetData] = React.useState<Option | null>(null)
  const [AssetDataOptions, setAssetDataOptions] = React.useState<Option[]>([])
  const [reportTemplate, setreportTemplate] = React.useState<Option | null>(null)
  const [selectedIntervalData, setIntervalData] = React.useState<Option | null>(null)
  const [selectedlocation, setselectedlocation] = React.useState<Option | null>(null)
  const [selectedlocationOptions, setselectedlocationOptions] = React.useState<Option[]>([])
  const [reportTemplateOptions, setreportTemplateOptions] = React.useState<Option[]>([])
  const [scheduleAssigemntOptions, setscheduleAssigemntNameOptions] = React.useState<Option[]>([])
  const [IntervalDataOptions, setIntervalDataOptions] = React.useState<Option[]>([])
  const [selectedScheduleAssigemnt, setSelectedscheduleAssigemnt] = React.useState<Option | null>(null)
  const [recurrencePattern, setRecurrencePattern] = React.useState<Option | null>(null)
  const [Monthday, setMonthDay] = React.useState<Option | null>(null)
  const [Weekday, setWeekDay] = React.useState<Option | null>(null)
  const [selectAssetType, setselectAssetType] = React.useState(true)
  const [selectGroupType, setselectGroupType] = React.useState(false)
  const [selectDeviceType, setselectDeviceType] = React.useState(false)
  const [daily, setDaily] = React.useState(false)
  const [weekly, setweekly] = React.useState(false)
  const [monthly, setMonthly] = React.useState(false)
  const [yearly, setyearly] = React.useState(false)
  const [Time, setTime] = React.useState<any>('')
  const defaultDateAsset = dayjs(new Date())
  const [startDate, setStartDate] = React.useState<Dayjs | null | undefined>(
    defaultDateAsset
  )
  const [endDate, setEndDate] = React.useState<Dayjs | null | undefined>(
    defaultDateAsset
  )
  const [monthDate, setMonthDate] = React.useState<Option | null>(null)
  const [confirm, setconfirm] = React.useState(false)
  const [showSuccessPopUp, setshowSuccessPopUp] = React.useState(false)
  const [reqError, setReqError] = React.useState<boolean>(false)
  const [selectSFTP, setselectSFTP] = React.useState<boolean>(false)
  const [dateAlertPopup, setdateAlertPopup] = React.useState(false)
  const [savedInitialState, setSavedInitialState] = React.useState<any>('')
  const scheduleAssigemntParameter = useSelector((state: RootState) => state.SchdulerDropDownListData.schdulerDropDownListData?.assignments)
  const intervalsData = useSelector((state: RootState) => state.SchdulerDropDownListData.schdulerDropDownListData?.intervals)
  const locationsData = useSelector((state: RootState) => state.SchdulerDropDownListData.schdulerDropDownListData?.locations)
  const tableData = useSelector((state: RootState) => state.templateDetailsListState.templateDetailsList)
  const groupData = useSelector((state: RootState) => state.assetGroupList.assetGroupsList?.group_details)
  const deviceData = useSelector((state: RootState) => state.deviceList.deviceList)
  const assetType = useSelector((state: RootState) => state.assetTypes.assetTypes)
  const configurationList = useSelector((state: RootState) => state.ConfigurationListData.ConfigurationListData)
  const configurationListStatus = useSelector((state: RootState) => state.ConfigurationListData.httpStatus)
  const reportSchedulerResponse = useSelector((state: RootState) => state.getReportSchedulerById)
  const editReportScheduler = useSelector((state: RootState) => state.editReportScheduler)
  const reportSchedulerData = useSelector((state: RootState) => state.getReportSchedulerById.SchdulerDetails)
  const auth = useAuth()
  const assetList = useSelector((state: RootState) => state.deviceList)
  const protocolIDValue = configurationList !== undefined ? configurationList[0]?.protocolId : null
  const protocolIDName = configurationList !== undefined ? configurationList[0]?.connectionName : null
  const loadingOption = { value: 'loading', label: 'Loading...', isDisabled: true }

  const initialState = {
    schedulerName: null,
    reportTemplateId: null,
    reportSchedulerId: null,
    schedulerIntervalId: null,
    schedulerLocationId: null,
    recurrencePattern: null,
    reportMonth: null,
    dayOfTheMonth: null,
    dayOfTheWeek: null,
    time: null,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    startDate: null,
    endDate: null,
    schedulerAssignmentId: null,
    assignmentValues: null,
    connectionName: null
  }

  const [addState, dispatch] = React.useReducer(
    (curVal: any, newVal: any) => ({ ...curVal, ...newVal }),
    initialState
  )
  const SchedulerId = sessionStorage.getItem('reportSchedulerId')

  React.useEffect(() => {
    props.fetchDeviceList(0, 10, 'asc', 'id', auth.user?.profile.customer as string)
    props.getAllCreateSchdulerDropdown()
    props.fetchTemplateDetailsList()
    props.fetchAssetGroupList(0, 2000, 'asc', 'assetGroupId', '')
    props.fetchAssetTypes()
    props.getConfigurationListDetails('createdAt', 'desc')
    fetchReportSchedulerById()
  }, [])

  const fetchReportSchedulerById = (): void => {
    props.getReportSchedulerById(SchedulerId != null ? SchedulerId : '')
  }

  React.useEffect(() => {
    if (reportSchedulerData !== undefined) {
      const savedData = {
        schedulerName: reportSchedulerData.schedulerName ?? '',
        reportTemplateId: reportSchedulerData.reportTemplateId ?? '',
        reportSchedulerId: reportSchedulerData.reportSchedulerId ?? '',
        schedulerIntervalId: reportSchedulerData.schedulerIntervalId ?? '',
        schedulerLocationId: reportSchedulerData.schedulerLocationId ?? '',
        recurrencePattern: reportSchedulerData.recurrencePattern ?? '',
        reportMonth: reportSchedulerData.reportMonth ?? '',
        dayOfTheMonth: reportSchedulerData.dayOfTheMonth ?? '',
        dayOfTheWeek: reportSchedulerData.dayOfTheWeek ?? '',
        time: reportSchedulerData.time ?? '',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        startDate: reportSchedulerData.startDate ?? '',
        endDate: reportSchedulerData.endDate ?? '',
        schedulerAssignmentId: reportSchedulerData.schedulerAssignmentId ?? '',
        assignmentValues: [reportSchedulerData.assetOrGroupAssignments[0].assetOrGroupId ?? ''],
        connectionName: protocolIDValue
      }
      setSavedInitialState(savedData)

      dispatch({ ...savedData })
      setSchedulerName(reportSchedulerData.schedulerName ?? '')
      setreportTemplate({
        label: reportSchedulerData.templateName ?? '',
        value: reportSchedulerData.reportTemplateId ?? ''
      })
      handleSavedScheduleAssigemnt({
        label: reportSchedulerData.schedulerAssignment ?? '',
        value: reportSchedulerData.schedulerAssignmentId ?? ''
      })

      // check later
      if (reportSchedulerData.assetOrGroupAssignments) {
        handleAssetAssigemntSub(reportSchedulerData.schedulerAssignment, reportSchedulerData.assetOrGroupAssignments)
      }

      setIntervalData({
        label: reportSchedulerData.schedulerInterval ?? '',
        value: reportSchedulerData.schedulerIntervalId ?? ''
      })
      setselectedlocation({
        label: reportSchedulerData.schedulerLocation ?? '',
        value: reportSchedulerData.schedulerLocationId ?? ''
      })
      setselectSFTP(true)

      // check
      setStartDate(dayjs(reportSchedulerData.startDate ?? ''))
      setEndDate(dayjs(reportSchedulerData.endDate ?? ''))

      handleSavedRecurrencePattern({
        label: reportSchedulerData.recurrencePattern ?? '',
        value: reportSchedulerData.recurrencePattern ?? ''
      })
      setTime(dayjs(reportSchedulerData.time, 'h:mm A'))

      if (reportSchedulerData.dayOfTheWeek) {
        setWeekDay({
          label: reportSchedulerData.dayOfTheWeek,
          value: reportSchedulerData.dayOfTheWeek
        })
      } else {
        setWeekDay(null)
      }

      if (reportSchedulerData.dayOfTheMonth) {
        setMonthDay({
          label: reportSchedulerData.dayOfTheMonth?.toString(),
          value: reportSchedulerData.dayOfTheMonth?.toString()
        })
      } else {
        setMonthDay(null)
      }

      if (reportSchedulerData.dayOfTheMonth?.toString() === '29' || reportSchedulerData.dayOfTheMonth?.toString() === '30' || reportSchedulerData.dayOfTheMonth?.toString() === '31') {
        setdateAlertPopup(true)
      }

      const selectedMonthInYr = MonthsInYear.find(month => month.value === reportSchedulerData?.reportMonth?.toString())
      if (selectedMonthInYr !== undefined) {
        setMonthDate(selectedMonthInYr)
      }
    }
  }, [reportSchedulerData])

  const handleSavedRecurrencePattern = (selectedValue: Option): void => {
    setRecurrencePattern(selectedValue)
    if (selectedValue.value === 'Daily') {
      setDaily(true)
      setMonthly(false)
      setyearly(false)
      setweekly(false)
    } else if (selectedValue.value === 'Weekly') {
      setweekly(true)
      setDaily(true)
      setMonthly(false)
      setyearly(false)
    } else if (selectedValue.value === 'Monthly') {
      setweekly(false)
      setMonthly(true)
      setDaily(true)
      setyearly(false)
    } else if (selectedValue.value === 'Yearly') {
      setyearly(true)
      setMonthly(true)
      setDaily(true)
      setweekly(false)
    }
  }

  const handleSavedScheduleAssigemnt = (selectedValue: Option): void => {
    setSelectedscheduleAssigemnt(selectedValue)
    if (selectedValue.label === 'Asset') {
      setselectAssetType(true)
      setselectGroupType(false)
      setselectDeviceType(false)
    } else if (selectedValue.label === 'Asset group') {
      setselectAssetType(false)
      setselectGroupType(true)
      setselectDeviceType(false)
    } else if (selectedValue.label === 'Asset type') {
      setselectAssetType(false)
      setselectGroupType(false)
      setselectDeviceType(true)
    } else if (selectedValue.label === 'All asset') {
      setselectAssetType(false)
      setselectGroupType(false)
      setselectDeviceType(false)
    }
  }

  const handleAssetAssigemntSub = (schedulerAssignment: any, assetOrGroupAssignments: any): void => {
    const data: Option = {
      label: assetOrGroupAssignments[0].assetOrGroupValue,
      value: assetOrGroupAssignments[0].assetOrGroupId
    }
    switch (schedulerAssignment) {
      case 'Asset type':
        setselectedAssetType(data)
        break
      case 'Asset group':
        setGroupData(data)
        break
      case 'Asset':
        setAssetData(data)
        break
      default:
        break
    }
  }

  React.useEffect(() => {
    if (tableData !== undefined && tableData?.length > 0) {
      const reportTemplateoption: Option[] = []
      tableData?.forEach((item: any) => {
        reportTemplateoption.push({
          value: item.reportTemplateId,
          label: item.reportName
        })
      })
      setreportTemplateOptions(reportTemplateoption)
    }
  }, [tableData])

  React.useEffect(() => {
    if (intervalsData !== undefined && intervalsData.length > 0) {
      const intervalsDataOptions: Option[] = []
      intervalsData?.forEach((item: any) => {
        intervalsDataOptions.push({
          value: item.schedulerIntervalId,
          label: item.schedulerInterval
        })
      })
      setIntervalDataOptions(intervalsDataOptions)
    }
  }, [intervalsData])

  React.useEffect(() => {
    if (scheduleAssigemntParameter !== undefined && scheduleAssigemntParameter.length > 0) {
      const scheduleAssigemntOptions: Option[] = []
      scheduleAssigemntParameter?.forEach((item: any) => {
        scheduleAssigemntOptions.push({
          value: item.schedulerAssignmentId,
          label: item.schedulerAssignment
        })
      })
      setscheduleAssigemntNameOptions(scheduleAssigemntOptions)
    }
  }, [scheduleAssigemntParameter])

  React.useEffect(() => {
    if (locationsData !== undefined && locationsData.length > 0) {
      const locationsDataOptions: Option[] = []
      locationsData?.forEach((item: any) => {
        locationsDataOptions.push({
          value: item.schedulerLocationId,
          label: item.schedulerLocation
        })
      })
      setselectedlocationOptions(locationsDataOptions)
    }
  }, [locationsData])

  React.useEffect(() => {
    if (groupData !== undefined && groupData.length > 0) {
      const GroupDataOptions: Option[] = []
      groupData?.forEach((item: any) => {
        GroupDataOptions.push({
          value: item.group_id,
          label: item.group_name
        })
      })
      setGroupDataOptions(GroupDataOptions)
    }
  }, [groupData])

  React.useEffect(() => {
    if (assetType !== undefined && assetType.length > 0) {
      const AssetTypeDataOptions: Option[] = []
      assetType?.forEach((item: any) => {
        AssetTypeDataOptions.push({
          value: item.asset_type_id,
          label: item.asset_type_name
        })
      })
      setselectedAssetOptions(AssetTypeDataOptions)
    }
  }, [groupData])

  React.useEffect(() => {
    if (deviceData !== null && deviceData.length > 0) {
      const deviceDataOptions: Option[] = []
      deviceData?.forEach((item: any) => {
        deviceDataOptions.push({
          value: item.id,
          label: item.name
        })
      })
      setAssetDataOptions(deviceDataOptions)
    }
  }, [deviceData])

  const handleschedulerName = (name: string, value: string): void => {
    setSchedulerName(value)
    dispatch({ ...addState, schedulerName: value })
  }

  const handleConfirmClick = (): void => {
    const id = sessionStorage.getItem('reportSchedulerId')
    if (id !== null) {
      props.editReportSchedulerTemplate(id, addState)
      setconfirm(false)
      setshowSuccessPopUp(true)
    }
  }

  const handleOkandClose = (): void => {
    if (reportSchedulerResponse.httpStatus === 201) {
      sessionStorage.setItem('currentSessionTab', '1')
      setshowSuccessPopUp(false)
      setconfirm(false)
      navigate('/datapub')
      clearAllDetails()
    } else {
      setshowSuccessPopUp(false)
      setconfirm(false)
    }
  }
  const handleBackNavigate = (): void => {
    sessionStorage.setItem('currentSessionTab', '1')
    navigate('/datapub')
  }

  const handleEditSchedulerDetails = (): JSX.Element => {
    // istanbul ignore else
    if (reportSchedulerResponse.httpStatus === 200) {
      return (
        <>
          <Box sx={style} className='add-Scheduler-container'>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item md={3}>
                  <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                    {CreateSchedulerStrings.ScheduleName}
                    <sup style={{ color: '#EE3426' }}>*</sup>
                  </span>
                  <TextField
                    id='scheduler-name'
                    variant='outlined'
                    data-testId='scheduler-name'
                    placeholder='Add Name'
                    disabled={true}
                    value={schedulerName}
                    onChange={(e) => {
                      handleschedulerName('schedulerName', e.target.value)
                    }}
                    size='small'
                    autoComplete='off'
                    className='scheduler-name-class'
                    sx={{
                      width: '270px',
                      color: '#fffff !important',
                      opacity: 0.5
                    }}
                  />
                  {addState.schedulerName !== null && !isValidName(addState.schedulerName) && (
                    <div className='error-response'>
                      <span className='error-upload-message'>
                        {CreateSchedulerStrings.enterValidText}
                      </span>
                    </div>
                  )}
                  {reqError && !addState.schedulerName && (
                    <div className='error-response'>
                      <span className='error-upload-message'>{CreateSchedulerStrings.required} -d {addState.schedulerName}</span>
                    </div>
                  )}
                  {addState.schedulerName !== null && !isValidlength(addState.schedulerName) && (
                    <div className='error-response'>
                      <span className='error-upload-message'>
                        {CreateSchedulerStrings.enterMoreText}
                      </span>
                    </div>
                  )}
                </Grid>
                <Grid item md={3}>
                  <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                    {CreateSchedulerStrings.reportTemplateSelection}
                    <sup style={{ color: '#EE3426' }}>*</sup>
                  </span>
                  <GenXDropdown
                    placeholder='Select Report Template'
                    value={reportTemplate}
                    options={reportTemplateOptions}
                    onSelect={handleReportTemplate}
                    width={270}
                    disabled={true}
                  />
                  {reqError && !addState.reportTemplateId && (
                    <div className='error-response'>
                      <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                    </div>
                  )}
                </Grid>
                <Grid item md={3}>
                  <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                    {CreateSchedulerStrings.schedulerAssignment}
                    <sup style={{ color: '#EE3426' }}>*</sup>
                  </span>
                  <GenXDropdown
                    placeholder='Select Assignment'
                    value={selectedScheduleAssigemnt}
                    options={scheduleAssigemntOptions}
                    onSelect={handleScheduleAssigemnt}
                    width={270}
                    disabled={true}
                  />
                  {reqError && !addState.schedulerAssignmentId && (
                    <div className='error-response'>
                      <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                    </div>
                  )}
                </Grid>
                {selectAssetType && (
                  <Grid item md={3}>
                    <span
                      style={{ fontSize: '15px', padding: 10, display: 'block' }}
                    >
                      {CreateSchedulerStrings.assetsAssignment}
                      <sup style={{ color: '#EE3426' }}>*</sup>
                    </span>
                    <GenXDropdown
                      placeholder='Select Asset'
                      value={selectedAssetData}
                      options={assetList.isLoading ? [loadingOption] : AssetDataOptions}
                      onSelect={(e: any) => { handleAssetAssigemnt('selectedAsset', e.target.value) }}
                      width={270}
                      disabled={true}
                    />
                    {reqError && !selectedAssetData && (
                      <div className='error-response'>
                        <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                      </div>
                    )}
                  </Grid>
                )}
                {selectGroupType && (
                  <Grid item md={3}>
                    <span
                      style={{ fontSize: '15px', padding: 10, display: 'block' }}
                    >
                      {CreateSchedulerStrings.GroupsAssignment}
                      <sup style={{ color: '#EE3426' }}>*</sup>
                    </span>
                    <GenXDropdown
                      placeholder='Select Group'
                      value={selectedGroupData}
                      options={GroupDataOptions}
                      onSelect={(e: any) => { handleGroupAssigemnt('selectedAsset', e.target.value) }}
                      width={270}
                      disabled={true}
                    />
                    {reqError && !selectedGroupData && (
                      <div className='error-response'>
                        <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                      </div>
                    )}
                  </Grid>
                )}
                {selectDeviceType && (
                  <Grid item md={3}>
                    <span
                      style={{ fontSize: '15px', padding: 10, display: 'block' }}
                    >
                      {CreateSchedulerStrings.AssetTypeAssignment}
                      <sup style={{ color: '#EE3426' }}>*</sup>
                    </span>
                    <GenXDropdown
                      placeholder='Select Asset Type'
                      value={selectedAssetType}
                      options={selectedAssetOptions}
                      onSelect={handleDeviceTypeAssigemnt}
                      width={270}
                      disabled={true}
                    />
                    {reqError && !selectedAssetType && (
                      <div className='error-response'>
                        <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                      </div>
                    )}
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item md={3}>
                  <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                    {CreateSchedulerStrings.dataIntervalSelection}
                    <sup style={{ color: '#EE3426' }}>*</sup>
                  </span>
                  <GenXDropdown
                    placeholder='Select Interval'
                    value={selectedIntervalData}
                    options={IntervalDataOptions}
                    onSelect={handleIntervalData}
                    width={270}
                    disabled={true}
                  />
                  {reqError && !addState.schedulerIntervalId && (
                    <div className='error-response'>
                      <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                    </div>
                  )}
                </Grid>
                <Grid item md={3}>
                  <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                    {CreateSchedulerStrings.location}
                    <sup style={{ color: '#EE3426' }}>*</sup>
                  </span>
                  <GenXDropdown
                    placeholder='Select Location'
                    value={selectedlocation}
                    options={selectedlocationOptions}
                    onSelect={handleLocationData}
                    width={270}
                    disabled={true}
                  />
                  {reqError && !addState.schedulerLocationId && (
                    <div className='error-response'>
                      <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                    </div>
                  )}
                </Grid>
                { selectSFTP && (<Grid item md={3}>
                  <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                    {'Configuration Name'}
                    <sup style={{ color: '#EE3426' }}>*</sup>
                  </span>
                  <TextField
                    id='Configuration-name'
                    variant='outlined'
                    data-testId='Configuration-name'
                    placeholder=''
                    disabled={true}
                    value={protocolIDName}
                    size='small'
                    autoComplete='off'
                    className='scheduler-name-class'
                    sx={{
                      width: '270px',
                      color: '#fffff !important',
                      opacity: 0.5
                    }}
                  />
                  <div className="row">
                    {(configurationListStatus === 404)
                      ? <div style={{ fontSize: '12px' }}>{CreateSchedulerStrings.connectionNotAvilable} <span style={{ color: '#1976d2', cursor: 'pointer' }} onClick={() => { navigate('/create-sftp-configuration') }}>here.</span></div>
                      : ''
                    }
                  </div>
                </Grid>)}
              </Grid>
            </CardContent>
          </Box>
          <Box sx={style} className='add-Scheduler-container'>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item md={3}>
                  <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                    {CreateSchedulerStrings.RecurrencePattern}
                    <sup style={{ color: '#EE3426' }}>*</sup>
                  </span>
                  <GenXDropdown
                    placeholder='Select Recurrence Pattern'
                    value={recurrencePattern}
                    options={recurrencepattern}
                    onSelect={handleRecurrencePattern}
                    width={270}
                  />
                  {reqError && !addState.recurrencePattern && (
                    <div className='error-response'>
                      <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                    </div>
                  )}
                </Grid>
                {yearly && (
                  <Grid item md={3}>
                    <span
                      style={{ fontSize: '15px', padding: 10, display: 'block' }}
                    >
                      {CreateSchedulerStrings.Month}
                      <sup style={{ color: '#EE3426' }}>*</sup>
                    </span>
                    <GenXDropdown
                      placeholder='Select Date'
                      value={monthDate}
                      options={MonthsInYear}
                      onSelect={handleMonthsInYear}
                      width={270}
                    />
                    {reqError && !addState.reportMonth && (
                      <div className='error-response'>
                        <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                      </div>
                    )}
                  </Grid>
                )}
                {monthly && (
                  <Grid item md={3}>
                    <span
                      style={{ fontSize: '15px', padding: 10, display: 'block' }}
                    >
                      {CreateSchedulerStrings.Date}
                      <sup style={{ color: '#EE3426' }}>*</sup>
                    </span>
                    <GenXDropdown
                      placeholder='Select Date'
                      value={Monthday}
                      options={Totaldays}
                      onSelect={handleMonthday}
                      width={270}
                    />
                    {reqError && !addState.dayOfTheMonth && (
                      <div className='error-response'>
                        <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                      </div>
                    )}
                  </Grid>
                )}
                {weekly && (
                  <Grid item md={3}>
                    <span
                      style={{ fontSize: '15px', padding: 10, display: 'block' }}
                    >
                      {CreateSchedulerStrings.selectDay}
                      <sup style={{ color: '#EE3426' }}>*</sup>
                    </span>
                    <GenXDropdown
                      placeholder='Select Day'
                      value={Weekday}
                      options={weekdays}
                      onSelect={handleWeeklyDay}
                      width={270}
                    />
                    {reqError && !addState.dayOfTheWeek && (
                      <div className='error-response'>
                        <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                      </div>
                    )}
                  </Grid>
                )}
                {daily && (
                  <Grid item md={3}>
                    <span
                      style={{ fontSize: '15px', padding: 10, display: 'block' }}
                    >
                      {CreateSchedulerStrings.time}
                      <sup style={{ color: '#EE3426' }}>*</sup>
                    </span>
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <WhiteTimePicker
                          data-testid='time-picker'
                          views={['hours', 'minutes']}
                          value={Time}
                          onChange={(val: any) => {
                            handleSelectedTime(val, 'Time')
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                    {reqError && !addState.time && (
                      <div className='error-response'>
                        <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                      </div>
                    )}
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item md={3}>
                  <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                    {CreateSchedulerStrings.startDate}
                    <sup style={{ color: '#EE3426' }}>*</sup>
                  </span>
                  <CustomLocalizationProviderWrapper>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <WhiteDatePicker
                        data-testid='date-picker'
                        disablePast
                        // defaultValue={defaultDateAsset}
                        slotProps={{ textField: { fullWidth: true }, field: { readOnly: true } }}
                        value={startDate}
                        onAccept={(val: any) => {
                          handleStartDate(dayjs(val))
                        }}
                      />
                    </LocalizationProvider>
                  </CustomLocalizationProviderWrapper>
                  {reqError && !addState.startDate && (
                    <div className='error-response'>
                      <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                    </div>
                  )}
                </Grid>
                <Grid item md={3}>
                  <span style={{ fontSize: '15px', padding: 10, display: 'block' }}>
                    {CreateSchedulerStrings.endDate}
                    <sup style={{ color: '#EE3426' }}>*</sup>
                  </span>
                  <CustomLocalizationProviderWrapper>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <WhiteDatePicker
                        disablePast
                        // defaultValue={defaultDateAsset}
                        slotProps={{ textField: { fullWidth: true }, field: { readOnly: true } }}
                        value={endDate}
                        onAccept={(val: any) => {
                          handleEndDate(dayjs(val))
                        }}
                      />
                    </LocalizationProvider>
                  </CustomLocalizationProviderWrapper>
                  {reqError && !addState.endDate && (
                    <div className='error-response'>
                      <span className='error-upload-message'>{CreateSchedulerStrings.required}</span>
                    </div>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Box>
          <Box sx={style} className='add-Scheduler-container'>
            <Grid container spacing={1}>
              <Grid style={{ textAlign: 'left' }} item md={6}>
                <Button type='hollow' data-testid='cancel-btn' onClick={() => {
                  handleBackNavigate()
                  clearAllDetails()
                }}>
                  {CreateSchedulerStrings.CancelBtn}
                </Button>
              </Grid>
              <Grid style={{ textAlign: 'right' }} item md={6}>
                <Button
                  onClick={() => {
                    validateFields()
                  }}
                  primaryColor={checkIfEditInfoValid() ? '#B0B0B0' : undefined}
                  disabled={checkIfEditInfoValid()}
                >
                  {CreateSchedulerStrings.UpdateBtn}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      )
    } else if (reportSchedulerResponse.isLoading) {
      return (
        <div className='CircularprogressAmi'>
          <CircularProgress />
        </div>
      )
    } else if (reportSchedulerResponse.httpStatus === 400) {
      return (
        <Modal buttonTypeNegative='hollow'
          title={CreateSchedulerStrings.ErrorTitle}
          LabelPositive={CreateSchedulerStrings.OkButton}
          onClickPositive={() => { handleOkandClose() }}
          onClose={() => { handleOkandClose() }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {reportSchedulerResponse.loadingError}
          </p>
        </Modal>
      )
    } else if (reportSchedulerResponse.httpStatus === 401) {
      return (
        <div className='authError'><AuthError errorMessage='Unauthorized' /></div>
      )
    } else if (reportSchedulerResponse.httpStatus === 403) {
      return (
        <div className='authError'><AuthError errorMessage='accessForbidden' /></div>
      )
    } else {
      return (
        <div className="authError">
          <AuthError
            errorMessage="cannotFetch"
            retry={fetchReportSchedulerById}
          />
        </div>
      )
    }
  }

  const handleEditSchedulerWithFailure = (): JSX.Element => {
    // istanbul ignore else
    if (editReportScheduler.httpStatus === 200) {
      return (
        <Modal
          buttonTypeNegative='hollow'
          title={CreateSchedulerStrings.UpdateSchedule}
          LabelPositive={CreateSchedulerStrings.OkButton}
          onClickPositive={() => {
            setshowSuccessPopUp(false)
            setconfirm(false)
            handleBackNavigate()
          }}
          onClose={() => {
            setshowSuccessPopUp(false)
            setconfirm(false)
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {CreateSchedulerStrings.UpdateSuccessMessage}
          </p>
        </Modal>
      )
    } else if (editReportScheduler.isLoading) {
      return (
        <Modal title={'Loding...'}>
          <div className="CircularprogressAmi">
            <CircularProgress />
          </div>
        </Modal>
      )
    } else if (editReportScheduler.httpStatus === 400) {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={CreateSchedulerStrings.ErrorTitle}
          LabelPositive={'ok'}
          onClickPositive={() => {
            handleOkandClose()
          }}
          onClose={() => {
            handleOkandClose()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>{editReportScheduler.error}</p>
        </Modal>
      )
    } else if (editReportScheduler.httpStatus === 401) {
      return (
        <div className="authError">
          <AuthError errorMessage="Unauthorized" />
        </div>
      )
    } else if (editReportScheduler.httpStatus === 403) {
      return (
        <div className="authError">
          <AuthError errorMessage="accessForbidden" />
        </div>
      )
    } else {
      return (
        <Modal
          buttonTypeNegative="hollow"
          title={CreateSchedulerStrings.ErrorTitle}
          LabelPositive={'ok'}
          onClickPositive={() => {
            handleOkandClose()
          }}
          onClose={() => {
            handleOkandClose()
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>{editReportScheduler.error}</p>
        </Modal>
      )
    }
  }

  const handleReportTemplate = (selectedValue: Option): void => {
    setreportTemplate(selectedValue)
    dispatch({ ...addState, reportTemplateId: selectedValue.value })
  }

  const clearAllDetails = (): void => {
    dispatch({ ...addState, schedulerName: '' })
    setreportTemplate(null)
    setSelectedscheduleAssigemnt(null)
    setAssetData(null)
    setGroupData(null)
    setselectedAssetType(null)
    setIntervalData(null)
    setselectedlocation(null)
    setRecurrencePattern(null)
    setStartDate(null)
    setEndDate(null)
    setMonthDate(null)
    setMonthDay(null)
    setWeekDay(null)
    setTime('')
  }

  const isValidlength = (value: string): boolean => {
    if (value !== null && value !== undefined) {
      return value.length > 5 && value.length < 100
    } else {
      return false
    }
  }

  const handleScheduleAssigemnt = (selectedValue: Option): void => {
    setSelectedscheduleAssigemnt(selectedValue)
    if (selectedValue.label === 'Asset') {
      setselectAssetType(true)
      setselectGroupType(false)
      setselectDeviceType(false)
      dispatch({ ...addState, assignmentValues: '' })
    } else if (selectedValue.label === 'Asset group') {
      dispatch({ ...addState, assignmentValues: '' })
      setselectAssetType(false)
      setselectGroupType(true)
      setselectDeviceType(false)
    } else if (selectedValue.label === 'Asset type') {
      dispatch({ ...addState, assignmentValues: '' })
      setselectAssetType(false)
      setselectGroupType(false)
      setselectDeviceType(true)
    } else if (selectedValue.label === 'All asset') {
      dispatch({ ...addState, assignmentValues: [] })
      setselectAssetType(false)
      setselectGroupType(false)
      setselectDeviceType(false)
    }
    dispatch({ ...addState, schedulerAssignmentId: selectedValue.value })
  }

  const handleGroupAssigemnt = (name: string, value: string): void => {
    setGroupData({ label: value !== undefined ? value : '', value })
    dispatch({ ...addState, assignmentValues: [value] })
  }

  const handleAssetAssigemnt = (name: string, value: string): void => {
    setAssetData({ label: value !== undefined ? value : '', value })
    // const assetName = assetList?.deviceList?.find((item: { id: any }) => item.id === value)?.name
    dispatch({ ...addState, assignmentValues: [value] })
  }

  const handleDeviceTypeAssigemnt = (selectedValue: Option): void => {
    setselectedAssetType(selectedValue)
    dispatch({ ...addState, assignmentValues: [selectedValue.value] })
  }

  const handleIntervalData = (selectedValue: Option): void => {
    setIntervalData(selectedValue)
    dispatch({ ...addState, schedulerIntervalId: selectedValue.value })
  }

  const handleLocationData = (selectedValue: Option): void => {
    setselectedlocation(selectedValue)
    setselectSFTP(true)
    dispatch({ ...addState, schedulerLocationId: selectedValue.value })
  }

  const handleStartDate = (val: any): void => {
    setStartDate(val)
    dispatch({ ...addState, startDate: val })
  }

  const handleEndDate = (val: any): void => {
    setEndDate(val)
    dispatch({ ...addState, endDate: val })
  }

  const handleMonthsInYear = (selectedValue: Option): void => {
    setMonthDate(selectedValue)
    dispatch({ ...addState, reportMonth: selectedValue.value })
  }

  const handleRecurrencePattern = (selectedValue: Option): void => {
    setRecurrencePattern(selectedValue)
    if (selectedValue.value === 'Daily') {
      setDaily(true)
      setMonthly(false)
      setyearly(false)
      setweekly(false)
    } else if (selectedValue.value === 'Weekly') {
      setweekly(true)
      setDaily(true)
      setMonthly(false)
      setyearly(false)
    } else if (selectedValue.value === 'Monthly') {
      setweekly(false)
      setMonthly(true)
      setDaily(true)
      setyearly(false)
    } else if (selectedValue.value === 'Yearly') {
      setyearly(true)
      setMonthly(true)
      setDaily(true)
      setweekly(false)
    }
    dispatch({ ...addState, recurrencePattern: selectedValue.value })
  }

  const handleMonthday = (selectedValue: Option): void => {
    setMonthDay(selectedValue)
    if (selectedValue.value === '29' || selectedValue.value === '30' || selectedValue.value === '31') {
      setdateAlertPopup(true)
    }
    dispatch({ ...addState, dayOfTheMonth: selectedValue.value })
  }

  const handleWeeklyDay = (selectedValue: Option): void => {
    setWeekDay(selectedValue)
    dispatch({ ...addState, dayOfTheWeek: selectedValue.value })
  }

  const handleSelectedTime = (val: any, Time: string): void => {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }
    const value = val.$d.toLocaleTimeString('en-US', options)
    setTime(val)
    dispatch({ ...addState, time: value })
  }

  const checkIfEditInfoValid = (): boolean => {
    return (JSON.stringify(savedInitialState) === JSON.stringify(addState))
  }

  const validateFields = (): void => {
    dispatch({ ...addState, protocolId: protocolIDValue })
    if (
      addState.schedulerName !== null &&
      addState.reportTemplateId !== null &&
      addState.schedulerAssignmentId !== null &&
      addState.schedulerIntervalId !== null &&
      addState.schedulerLocationId !== null &&
      addState.startDate !== null &&
      addState.endDate !== null &&
      configurationListStatus === 200
    ) {
      if (addState.schedulerName) {
        if (!isValidName(addState.schedulerName)) {
          setReqError(true)
          return
        }
      }

      if (addState.schedulerName) {
        if (!isValidlength(addState.schedulerName)) {
          setReqError(true)
          return
        }
      }

      if (addState.recurrencePattern === null) {
        setReqError(true)
        return
      }

      if (recurrencePattern?.label === 'Daily') {
        if (Time === null) {
          setReqError(true)
          return
        }
      }

      if (recurrencePattern?.label === 'Weekly') {
        if (Time === null) {
          setReqError(true)
          return
        } else if (Weekday === null) {
          setReqError(true)
          return
        }
      }

      if (recurrencePattern?.label === 'Monthly') {
        if (Time === null) {
          setReqError(true)
          return
        } else if (Monthday === null) {
          setReqError(true)
          return
        }
      }

      if (recurrencePattern?.label === 'Yearly') {
        if (Time === null) {
          setReqError(true)
          return
        } else if (monthDate === null) {
          setReqError(true)
          return
        } else if (Monthday === null) {
          setReqError(true)
          return
        }
      }
      if (selectedScheduleAssigemnt?.label === 'Asset') {
        if (selectedAssetData === null) {
          setReqError(true)
          return
        }
      } else if (selectedScheduleAssigemnt?.label === 'Group') {
        if (selectedGroupData === null) {
          setReqError(true)
          return
        }
      } else if (selectedScheduleAssigemnt?.label === 'Asset type') {
        if (selectedAssetType === null) {
          setReqError(true)
          return
        }
      }
      setconfirm(true)
    } else {
      setReqError(true)
    }
  }

  return (
    <>
      <div
        role='presentation'
        data-testid='breadcrumbs'
        style={{ width: '100%' }}
      >
        <Breadcrumbs
          aria-name='breadcrumb'
          sx={{
            color: '#f0f0f0',
            fontSize: '12px',
            marginBottom: '14px',
            fontFamily: 'Honeywell Sans Web',
            marginTop: '12px'
          }}
        >
          <Link
            underline='hover'
            color='inherit'
            data-testid='scheduler-breadcrumb'
            onClick={() => {
              navigate('/datapub')
            }}
          >
            {CreateSchedulerStrings.dataPublisher}
          </Link>
          <Typography
            color='inherit'
            sx={{ fontSize: '12px', fontFamily: 'Honeywell Sans Web' }}
          >
            {CreateSchedulerStrings.editSchedule}
          </Typography>
        </Breadcrumbs>
      </div>
      <div style={{ display: 'flex' }}>
        <ArrowBackIos
          sx={{ cursor: 'pointer' }}
          data-testid='back-arrow'
          onClick={() => {
            handleBackNavigate()
          }}
        />
        <div style={{ marginBottom: '32px' }}>
          <span
            data-testid='schedule-title'
            style={{
              color: '#f0f0f0',
              fontSize: '24px',
              fontWeight: '900',
              paddingRight: '16px',
              marginRight: '10px'
            }}
          >
            {CreateSchedulerStrings.editSchedule}
          </span>
        </div>
      </div>
      { handleEditSchedulerDetails() }
      {confirm && (
        <Modal
          titleStyle={{ paddingRight: '2em', marginBottom: '20px' }}
          buttonTypeNegative='hollow'
          LabelPositive={CreateSchedulerStrings.ConfirmBtn}
          LabelNegative={CreateSchedulerStrings.CancelBtn}
          onClickPositive={() => {
            handleConfirmClick()
          }}
          onClose={() => {
            setconfirm(false)
          }}
          onClickNegative={() => {
            setconfirm(false)
          }}
          title={CreateSchedulerStrings.UpdateSchedule}
        >
          <div className='confirm-msg' style={{ marginBottom: '30px' }}>
            <span>{CreateSchedulerStrings.ConfirmUpdateSchedule}</span>
          </div>
        </Modal>
      )}
      { showSuccessPopUp && handleEditSchedulerWithFailure() }
      {dateAlertPopup && (
        <Modal
          buttonTypeNegative='hollow'
          title={CreateSchedulerStrings.DateAlertTitle}
          LabelPositive='Ok'
          onClickPositive={() => {
            setdateAlertPopup(false)
          }}
          onClose={() => {
            setdateAlertPopup(false)
          }}
        >
          <p style={{ paddingTop: '1.2em' }}>
            {CreateSchedulerStrings.dateAlert}
          </p>
        </Modal>
      )}
    </>
  )
}

interface DispatchToProps {
  getAllCreateSchdulerDropdown: () => void
  editReportSchedulerTemplate: (reportSchedulerId: string, editReportSchedulerTemplatePutRequest: EditReportSchedulerData) => void
  fetchTemplateDetailsList: () => void
  fetchAssetGroupList: (page: number, size: number, sortdir: string, sortfield: string, input: string) => void
  fetchDeviceList: (page: number, size: number, sortdir: string, sortfield: string, tenantId: string) => void
  fetchAssetBySearchValue: (searchVal: string, tenantId: string, page: number, size: number, sortfield: string, sortdir: string) => void
  fetchAssetTypes: () => void
  getConfigurationListDetails: (sortdir: string, sortfield: string) => void
  getReportSchedulerById: (reportSchedulerId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  getAllCreateSchdulerDropdown: () => dispatch(getAllCreateSchdulerDropdown()),
  fetchTemplateDetailsList: () => dispatch(fetchTemplateDetailsList()),
  editReportSchedulerTemplate: (reportSchedulerId: string, editReportSchedulerTemplatePostRequest: EditReportSchedulerData) => dispatch(editReportSchedulerTemplate(reportSchedulerId, editReportSchedulerTemplatePostRequest)),
  fetchAssetGroupList: (page: number, size: number, sortdir: string, sortfield: string, input: string) => dispatch(fetchAssetGroupList(page, size, sortdir, sortfield, input)),
  fetchDeviceList: (page: number, size: number, sortdir: string, sortfield: string, tenantId: string) => dispatch(fetchDeviceList(page, size, sortdir, sortfield, tenantId)),
  fetchAssetBySearchValue: (searchVal: string, tenantId: string, page: number, size: number, sortfield: string, sortdir: string) => dispatch(fetchAssetBySearchValue(searchVal, tenantId, page, size, sortfield, sortdir)),
  fetchAssetTypes: () => dispatch(fetchAssetTypes()),
  getConfigurationListDetails: (sortdir: string, sortfield: string) =>
    dispatch(getConfigurationListDetails(sortdir, sortfield)),
  getReportSchedulerById: (reportSchedulerId: string) =>
    dispatch(getReportSchedulerById(reportSchedulerId))
})

interface StateToProps {
  SchdulerDropDownListData: CreateSchedulerDropDownState
  editSchedulerTemplate: EditReportSchedulerTemplateState
  ConfigurationListData: ConfigurationListDataState
  getReportSchedulerById: ISchdulerDetailsState
}

const mapStateToProps = (state: RootState): StateToProps => ({
  SchdulerDropDownListData: state.SchdulerDropDownListData,
  editSchedulerTemplate: state.editReportScheduler,
  ConfigurationListData: state.ConfigurationListData,
  getReportSchedulerById: state.getReportSchedulerById
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(EditReportScheduler)
