/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react'
import * as HighCharts from 'highcharts'
import { Card } from '@scuf/common'
import { Box, CircularProgress, styled } from '@mui/material'
import HighchartsReact from 'highcharts-react-official'
import DarkUnica from 'highcharts/themes/dark-unica'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs, { type Dayjs } from 'dayjs'
import LanguageStrings from '../../../i18n/locales'
import AuthError from '../../../components/ErrorComponents/AuthError'
import { DesktopDateTimePicker } from '@mui/x-date-pickers'

const nintyDayDate = dayjs(new Date((new Date()).setMonth(new Date().getMonth() - 3)))
const CellularSignalStrength = (props: { fetchSignalData: any, tenant: any, signalData: any, assetId: any }): JSX.Element => {
  const CellularStrings = LanguageStrings().DeviceDetailsStrings.Cellular
  let paramAssetId = sessionStorage.getItem('paramAssetId')
  // istanbul ignore next
  if (paramAssetId === null) {
    paramAssetId = ''
  }
  const defaultDate = {
    end: dayjs(new Date()),
    start: dayjs(new Date((new Date()).setMonth(new Date().getMonth() - 3))).clone().hour(0).minute(0).second(0)
  }
  const [start, setStart] = useState<Dayjs | null | undefined>(defaultDate.start)
  const [end, setEnd] = useState<Dayjs | null | undefined>(defaultDate.end)
  DarkUnica(HighCharts)

  // istanbul ignore next
  const handleDateChange = (type: string, date: dayjs.Dayjs): void => {
    if (type === 'start') {
      if (date.isAfter(end) || date.isSame(end)) {
        setStart(date)
        setEnd(date)
      } else {
        setStart(date)
      }
    } else {
      if (date.isBefore(start) || date.isSame(start)) {
        setEnd(date)
        setStart(date)
      } else {
        setEnd(date)
      }
    }
  }

  const fetchSignaldata = (): any => {
    // istanbul ignore next
    if (paramAssetId !== undefined && start !== (undefined) && start !== null && end !== undefined && end !== null && start <= end) {
      const startMoment = start
        .clone()
        .set('second', 0)
      const endMoment = end
        .clone()
        .set('second', 59)
      props.fetchSignalData(props.tenant, props.assetId, startMoment.format('YYYY-MM-DDTHH:mm:ssZ'), endMoment.format('YYYY-MM-DDTHH:mm:ssZ'))
    }
  }
  useEffect(() => {
    fetchSignaldata()
  }, [start, end])

  const WhiteDatePicker = styled(DesktopDateTimePicker)({
    '& input': {
      color: '#D0D0D0',
      fontSize: '14px',
      paddingRight: 0
    }
  })

  const CustomLocalizationProviderWrapper = styled('div')({
    backgroundColor: '#505050',
    border: 'none',
    borderRadius: '4px'
  })

  function getGraph (): JSX.Element {
    if (props.signalData.isLoading) {
      return <CircularProgress />
    } else if (props.signalData.httpStatus === 401) {
      return (
      <div className='authError'><AuthError errorMessage="Unauthorized"/></div>
      )
    } else if (props.signalData.httpStatus === 403) {
      return (
            <div className='authError'><AuthError errorMessage="accessForbidden"/></div>
      )
    } else if ((props.signalData.channelDataOptions === null && props.signalData.errorMessage === true && props.signalData.httpStatus === 200)) {
      return (
        <div className='authError'><AuthError errorMessage="NoDataPresent"/></div>
      )
    } else if (props.signalData.rawData) {
      return (
        <div className='authError'><AuthError errorMessage="NoDataPresent"/></div>
      )
    } else if (
      props.signalData.channelDataOptions !== undefined &&
      props.signalData.channelDataOptions !== null
    ) {
      return (
        <HighchartsReact
          highcharts={HighCharts}
          options={props.signalData.channelDataOptions?.consumptionChartOptions}
        />
      )
    } else {
      return (
        <div className='authError'><AuthError errorMessage="cannotFetch" retry={fetchSignaldata}/></div>
      )
    }
  }

  return (
    <div className='devicedetails'>
      <Box sx={{ width: '100%', padding: '1em' }}>
        <Card>
        <Card.Header title={CellularStrings.title} style={{ fontSize: '14px' }}>
          <span style={{ paddingRight: '1em', display: 'flex', alignItems: 'center' }}>
            <span style={{ paddingRight: '6px', fontSize: '14px' }}>
            Start Date
            </span>
            <span className='datePicker-deviceDetails'>
              <CustomLocalizationProviderWrapper>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <WhiteDatePicker minDate={nintyDayDate} disableFuture closeOnSelect={false} ampm={false} timeSteps={{ minutes: 1 }} slotProps={{ textField: { size: 'small' }, actionBar: { actions: ['cancel', 'accept'] }, field: { readOnly: true } }} value={start} onAccept={(val: any) => { handleDateChange('start', val) }} />
                </LocalizationProvider>
              </CustomLocalizationProviderWrapper>
            </span>
          </span>
          <span style={{ paddingRight: '1em', display: 'flex', alignItems: 'center' }}>
            <span style={{ paddingRight: '6px', fontSize: '14px' }}>
            End Date
            </span>
            <span className='datePicker-deviceDetails'>
              <CustomLocalizationProviderWrapper>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <WhiteDatePicker minDate={nintyDayDate} disableFuture closeOnSelect={false} ampm={false} timeSteps={{ minutes: 1 }} slotProps={{ textField: { size: 'small' }, actionBar: { actions: ['cancel', 'accept'] }, field: { readOnly: true } }} value={end} onAccept={(val: any) => { handleDateChange('end', val) }} />
                </LocalizationProvider>
              </CustomLocalizationProviderWrapper>
            </span>
          </span>
        </Card.Header>
        <Card.Content>
          {getGraph()}
          </Card.Content>
        </Card>
      </Box>
    </div>
  )
}
export default CellularSignalStrength
