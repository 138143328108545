import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface cimCode {
  id: string
  cimCode: string
  readingTypeName: string
  units: string
  description: string
}

export interface ChannelList {
  channelId: string
  channelName: string
  cimCodeId: string
  persistedInMDC: string
  cimCode: cimCode
  assetId: string
  multiplier: number
  intervalLength: number
  lastReadingTime: string
}

export interface channelDropdownInterface {
  isLoading: boolean
  errorMessage: string | null
  channelDropdownOptionData: ChannelList[] | null
}

const initialState: channelDropdownInterface = {
  isLoading: true,
  errorMessage: null,
  channelDropdownOptionData: null
}

export const channelDropdownDataSlice = createSlice({
  name: 'channelDropdownData',
  initialState,
  reducers: {
    addChannelDropdownData: (state, action: PayloadAction<ChannelList[]>) => {
      return {
        isLoading: false,
        errorMessage: null,
        channelDropdownOptionData: action.payload
      }
    },
    channelDropdownLoading: (state) => {
      return {
        isLoading: true,
        errorMessage: null,
        channelDropdownOptionData: null
      }
    },
    channelDropdownError: (state, action: PayloadAction<string>) => {
      return {
        isLoading: false,
        errorMessage: action.payload,
        channelDropdownOptionData: null
      }
    }
  }
})

export const { addChannelDropdownData, channelDropdownLoading, channelDropdownError } = channelDropdownDataSlice.actions
export default channelDropdownDataSlice.reducer
