import dayjs from 'dayjs'
import React from 'react'
import ReactApexChart, { type Props } from 'react-apexcharts'
import { type BarGraphParameter } from '../../KpiDetails'
import { type KpiKey } from '../../redux/changeKpiType'
import { getToggleOptions } from './kpiUtils'

export interface seriesData {
  name: string
  data: number[]
}

export interface ReportData {
  name: string
  type: string
  categories: string[]
  series: seriesData[]
  colors: string[]
  suffix: string
}

export function getReactApexChartBar (reportData: ReportData, height: string, isHistory: boolean, setBarGraphParameter?: React.Dispatch<React.SetStateAction<BarGraphParameter>>, selectedKpi?: KpiKey): any {
  const categories = reportData.categories
  const highestValue = Math.max(...reportData.series.map((g) => {
    return Math.max(...g.data)
  }))

  const chartOptions: Props = {
    series: reportData.series.map((g, idx) => {
      return {
        name: g.name,
        data: g.data.map((d, i) => {
          if ((d / highestValue * 100) < 2 && d !== 0) {
            return highestValue * 0.02
          }
          return d
        })
      }
    }),
    options: {
      chart: {
        height: 300,
        type: 'bar',
        background: 'transparent',
        toolbar: {
          show: false
        },
        events: {
          dataPointSelection: function (event, chartContext, config) {
            if (isHistory && setBarGraphParameter !== undefined && selectedKpi !== undefined) {
              console.log(categories[config.dataPointIndex])
              setBarGraphParameter((prev) => { return { date: dayjs(categories[config.dataPointIndex]), bar: getToggleOptions(selectedKpi)[config.seriesIndex] } })
            }
          }
        },
        zoom: {
          enabled: false
          // autoScaleYaxis: true
        }
      },
      theme: { mode: 'dark' },
      colors: reportData.colors,
      plotOptions: {
        bar: {
          borderRadius: 2,
          columnWidth: '45%',
          dataLabels: {
            position: 'center' // top, center, bottom
          }
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ['#313031']
      },
      dataLabels: {
        enabled: false,
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#fff']
        }
      },
      xaxis: {
        categories,
        tickPlacement: 'on',
        position: 'bottom',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: true
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#D8E3F0',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5
            }
          }
        },
        tooltip: {
          enabled: false
        },
        labels: {
          show: true,
          rotate: (window.innerWidth < 1550 && isHistory) ? -45 : 0,
          rotateAlways: (window.innerWidth < 1550 && isHistory),
          style: {
            colors: '#979798'
          },
          formatter: function (val) {
            const date = new Date(val).toDateString().split(' ')
            if (isHistory) {
              return `${date[2]} ${date[1]}`
            } else {
              return `${date[0]}`
            }
          }
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        // decimalsInFloat: 0,
        axisTicks: {
          show: false
        },
        labels: {
          show: true,
          style: {
            colors: '#bbbbbc'
          },
          formatter: function (val, opt) {
            console.log(opt)
            const s: string = reportData.suffix
            if (opt?.seriesIndex !== undefined && opt?.dataPointIndex !== undefined && reportData.series[opt.seriesIndex] !== undefined) {
              return `${Math.round(reportData.series[opt.seriesIndex].data[opt.dataPointIndex])} ${s} units`
            } else {
              return `${Math.round(val)} ${s} units`
            }
          }
        }
      },
      legend: {
        show: true,
        onItemClick: {
          toggleDataSeries: false
        },
        onItemHover: {
          highlightDataSeries: false
        }
      }
    }
  }

  return (
      <ReactApexChart
          options={chartOptions.options}
          series={chartOptions.series}
          type='bar' height={height}
      />
  )
}
