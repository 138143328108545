import React, { useEffect } from 'react'
import { Box, Breadcrumbs, Link, Tab, Tabs, Typography } from '@mui/material'
import Overview from './components/Overview'
import { type ConnectedProps, connect } from 'react-redux'
import { type RootState } from '../../store'
import { type AssetByIdState } from './redux/assetByIdSlice'
import { fetchAssetData, fetchChannelData, fetchAlarmsById, fetchSignalData, fetchChannelDropdownData } from './redux/actionCreator'
import { changeLanguage } from '../../store/language'
import LanguageStrings from '../../i18n/locales'
import { changeAssetId } from './redux/assetIdSlice'
import DataSource from './components/DataSource'
import { type AlarmByIdState } from './redux/alarmsByIdSlice'
import CellularSignalStrength from './components/CellularSignalStrength'
import { type signalState } from './redux/cellularSignalSlice'
import moment from 'moment'
import { useAuth } from 'react-oidc-context'
import { type channelDropdownInterface } from './redux/channelDropdownSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ArrowBackIos from '@mui/icons-material/ArrowBackIos'
import { TabPanel, a11yProps } from '../../components/TabsHelper'

const DeviceDetails = (props: AllProps): JSX.Element => {
  const [value, setValue] = React.useState(0)
  const auth = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const activeStyle = { fontSize: '12px', color: '#F0F0F0' }
  const inActiveStyle = { fontSize: '12px', color: '#A0A0A0' }
  const usrlang = navigator.language
  props.changeLanguage(usrlang)
  const ipConfigState = useSelector((state: RootState) => state.ipConfigState)
  const DeviceDetailsStrings = LanguageStrings().DeviceDetailsStrings
  let paramAssetId = sessionStorage.getItem('paramAssetId')
  let paramAssetName = sessionStorage.getItem('paramAssetName')

  // istanbul ignore next
  if (paramAssetId === null) {
    paramAssetId = ''
  }
  // istanbul ignore next
  if (paramAssetName === null) {
    paramAssetName = ''
  }

  const handleFetchAssetData = (): void => {
    if (paramAssetName !== undefined) {
      props.fetchAssetData(paramAssetId as string, auth.user?.profile.customer as string)
    }
  }

  useEffect(() => {
    const end = moment()
    end.set({ hour: 23, minute: 59, second: 59 })
    // istanbul ignore else
    if (paramAssetName !== undefined) {
      props.fetchChannelDropdownData(paramAssetId ?? '', auth.user?.profile.customer as string)
    }
    // istanbul ignore else
    if (paramAssetId !== undefined) {
      handleFetchAssetData()
      props.fetchChannelDropdownData(paramAssetId ?? '', auth.user?.profile.customer as string)
    }
  }, [ipConfigState])

  // istanbul ignore next
  const fetchAlarmsById = (): void => {
    console.log('Api called 2')
    if (props.assetId !== '') {
      console.log('Api called 3', props.assetId)
      props.fetchAlarmsById(props.assetId, auth.user?.profile.customer as string)
    } else if (paramAssetId !== undefined) {
      console.log('Api called 4', paramAssetId)
      props.fetchAlarmsById(paramAssetId as string, auth.user?.profile.customer as string)
    }
  }

  useEffect(() => {
    console.log('Api called 1')
    fetchAlarmsById()
  }, [props.assetId])

  const handleChange = (event: React.SyntheticEvent, newValue: number): any => {
    setValue(newValue)
  }

  let assetId = ''
  // istanbul ignore else
  if (paramAssetId !== undefined) {
    assetId = paramAssetId
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getStyle = (index: number) => {
    if (index === value) return activeStyle
    return inActiveStyle
  }

  function handleBreadCrumbsClick (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    event.preventDefault()
  }

  const getPreviousPath = (): string => {
    return location.state?.previousPath ?? '/asset-list'
  }

  getPreviousPath()

  const grandParentBreadcrumbRoute = props.grandParentBreadcrumb === LanguageStrings().Dashboard.Asset_Management ? '/card' : '/isense'

  return (
    <>
    <div className='devicedetails' style={{ width: 'calc(100vw - 3.5rem)' }}>
    <div onClick={handleBreadCrumbsClick} className='DeviceDetailHeading' >
        <Breadcrumbs aria-label="breadcrumb" sx={{ color: '#f0f0f0', fontSize: '12px', marginLeft: '1em', fontFamily: 'Honeywell Sans Web', marginTop: '12px' }}>
            <Link aria-label="navBreadcrumb" className='assetmgmtli' underline='none' color="inherit" onClick={() => { navigate(grandParentBreadcrumbRoute) }} >
            {props.grandParentBreadcrumb}
            </Link>

            {getPreviousPath() === '/asset-list' && <Typography color="inherit" sx={{ fontSize: '12px', fontFamily: 'Honeywell Sans Web' }} onClick={() => { navigate('/asset-list') }} className='assetmgmtli'>{DeviceDetailsStrings.Overview.Asset_List}</Typography> }
            <Typography color="inherit" sx={{ fontSize: '12px', fontFamily: 'Honeywell Sans Web' }}>{paramAssetName}</Typography>
        </Breadcrumbs>
    </div>
      <div className="topUtilityBar">
      <ArrowBackIos sx={{ cursor: 'pointer', marginRight: '-10px', marginTop: '5px' }} data-testid='back-arrow'onClick={() => { navigate(getPreviousPath()) }} />
      <span id="componentTitle">{paramAssetName}</span>
      </div>
      <Box sx={{ padding: '1em' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab style={getStyle(0)} label="Overview" {...a11yProps(0)} />
            <Tab style={getStyle(1)} label="Data Sources" {...a11yProps(1)} />
            <Tab style={getStyle(2)} label="Network" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Overview handleFetchAssetData={handleFetchAssetData} fetchAlarmsById={fetchAlarmsById} OverviewStrings={DeviceDetailsStrings.Overview} assetData={props.assetData} alarmsById={props.alarmsById} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DataSource chartData={props.channelDataDeviceDetail} channelDropdownData={props.channelDropdownData} fetchChannelData={props.fetchChannelData} fetchChannelDropdownData={props.fetchChannelDropdownData}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CellularSignalStrength fetchSignalData={props.fetchSignalData} tenant={auth.user?.profile.customer as string} signalData={props.signalData} assetId={assetId}/>
        </TabPanel>
      </Box>
    </div>
    </>
  )
}

interface DispatchToProps {
  fetchAssetData: (id: string, tenantId: string) => void
  changeLanguage: (count: string) => void
  changeAssetId: (id: string) => void
  fetchChannelData: (assetId: string, fromDate: string, toDate: string, selectedChannel: string, selectedChannelUnit: string, tenantId: string) => void
  fetchAlarmsById: (id: string, tenantId: string) => void
  fetchSignalData: (tenant: string, assetId: string, from: string, to: string) => void
  fetchChannelDropdownData: (assetId: string, tenantId: string) => void
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  fetchAssetData: (id: string, tenantId: string) => dispatch(fetchAssetData(id, tenantId)),
  changeLanguage: (language: string) => dispatch(changeLanguage(language)),
  changeAssetId: (id: string) => dispatch(changeAssetId(id)),
  fetchChannelData: (assetId: string, fromDate: string, toDate: string, selectedChannel: string, selectedChannelUnit: string, tenantId: string) => dispatch(fetchChannelData(assetId, fromDate, toDate, selectedChannel, selectedChannelUnit, tenantId)),
  fetchAlarmsById: (id: string, tenantId: string) => dispatch(fetchAlarmsById(id, tenantId)),
  fetchSignalData: (tenant: string, assetId: string, from: string, to: string) => dispatch(fetchSignalData(tenant, assetId, from, to)),
  fetchChannelDropdownData: (assetId: string, tenantId: string) => dispatch(fetchChannelDropdownData(assetId, tenantId))
})

interface StateToProps {
  assetData: AssetByIdState
  language: string
  assetId: string
  channelDataDeviceDetail: any
  alarmsById: AlarmByIdState
  signalData: signalState
  activeTenant: string
  channelDropdownData: channelDropdownInterface
  grandParentBreadcrumb: string
}

const mapStateToProps = (state: RootState): StateToProps => ({
  assetData: state.assetById,
  language: state.language,
  assetId: state.assetId,
  channelDataDeviceDetail: state.channelDataDeviceDetail,
  alarmsById: state.alarmsById,
  signalData: state.signalData,
  activeTenant: state.tenant,
  channelDropdownData: state.channelDropdownData,
  grandParentBreadcrumb: state.grandParentBreadcrumb
})

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

type AllProps = PropsFromRedux

export default connector(DeviceDetails)
