import { z } from 'zod'
import interceptedAxios from '../../../utils/interceptedAxios'

const ZConfigurationDetails = z.object({
  protocolId: z.string(),
  connectionName: z.string(),
  description: z.string().nullable(),
  protocolTypeId: z.string(),
  protocolTypeName: z.string(),
  protocolName: z.string(),
  ipAddress: z.string(),
  port: z.number(),
  userName: z.string().nullable(),
  password: z.string().nullable(),
  folderPath: z.string().nullable(),
  tenantId: z.string(),
  sshKey: z.string().nullable(),
  publicKey: z.string().nullable(),
  modifiedAt: z.string()
})

const ZData = z.object({
  totalCount: z.number(),
  protocolDetails: ZConfigurationDetails.array()
})

const ZApiResponse = z.object({
  http_status: z.number(),
  error: z.boolean(),
  data: z.optional(ZData)
})

export type ConfigurationListDetails = z.infer<typeof ZConfigurationDetails>
export type ConfigurationListData = z.infer<typeof ZConfigurationDetails>

export async function getConfigurationProtocolList (
  sortdir: string,
  sortfield: string,
  onSuccess: (ConfigurationListData: any, httpStatus: number) => any,
  onFailure: (errorMessage: any, httpStatus: number) => any
): Promise<void> {
  const url = `/app-config-service/api/v1/udp/configure-protocol/details?&sort=${sortdir},${sortfield}`
  interceptedAxios
    .get(url, { timeout: 10000 })
    .then((response) => {
      if (response.status === 200) {
        const parseResponse = ZApiResponse.safeParse(response.data)
        if (parseResponse.success) {
          if (response.data.data !== null && response.data.data !== undefined) {
            onSuccess(parseResponse.data.data, response.status)
          }
        } else {
          onFailure(parseResponse.error.message, response.status)
        }
      }
    })
    .catch((error) => {
      if (error.response !== undefined) {
        if (error.response.data !== undefined) {
          onFailure(error.response.data.error_message, error.response.status)
        } else {
          onFailure(error.response.statusText, error.response.status)
        }
      } else {
        onFailure('Network Failure', error.status)
      }
    })
}
