/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { type Options, type PointOptionsObject, type SeriesLineOptions, type SeriesAreasplineOptions } from 'highcharts'
import { convertDateIntoAohStandardUsingDayjs, parseDateInAOHstandard } from '../../../../customHooks/parseDate'

interface CustomSeries extends Highcharts.Series {
  processedYData: number[]
}

// istanbul ignore next
function networkDetailTooltip (kpi: string) {
  switch (kpi) {
    case 'Cellular Signal Strength':
      return 'RSSI'
    case 'Signal Quality':
      return 'RSRQ'
    case 'Transmission Power':
      return 'RSTP'
    default:
      return ''
  }
}

// istanbul ignore next
export function getUnitForKpi (kpi: string) {
  switch (kpi) {
    case 'Cellular Signal Strength':
      return 'dBm'
    case 'Signal Quality':
      return 'dB'
    case 'Transmission Power':
      return 'dBm'
    default:
      return 'dBm'
  }
}

export function getAssetDataOptions (
  historyData: Array<(number | [(number | string), (number | null)] | null | PointOptionsObject)> = [[null, null]]
): Options {
  const seriesOptions: SeriesAreasplineOptions[] = [
    {
      type: 'areaspline',
      name: 'Asset Health',
      data: historyData,
      marker: { enabled: false },
      lineColor: '#97E7CC99',
      lineWidth: 10
    }
  ]

  return ({
    chart: {
      type: 'areaspline',
      style: {
        fontSize: '16px',
        fontFamily: 'Honeywell Sans Web'
      }
    },
    title: {
      text: ''
    },
    series: seriesOptions,
    colors: [
      {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1
        },
        stops: [
          [0, '#97E7CC'],
          [1, '#C4C4C400']
        ]
      }
    ],
    legend: {
      itemStyle: {
        fontWeight: '400',
        fontFamily: 'Honeywell Sans Web',
        fontSize: '16px'
      }
    },
    xAxis: {
      title: {
        text: 'Date'
      },
      type: 'datetime'
    },
    yAxis: {
      title: {
        text: '',
        style: {
          fontSize: '16px',
          fontFamily: 'Honeywell Sans Web'
        }
      }
    }
  })
}

export function getPressureDataOptions (
  maxPressureData: Array<(number | [(number | string), (number | null)] | null | PointOptionsObject)> = [[null, null]],
  avgpressureData: Array<(number | [(number | string), (number | null)] | null | PointOptionsObject)> = [[null, null]],
  minPressureData: Array<(number | [(number | string), (number | null)] | null | PointOptionsObject)> = [[null, null]]
): Options {
  const seriesOptions: SeriesLineOptions[] = [
    {
      type: 'line',
      name: 'Max Pressure',
      data: maxPressureData,
      lineWidth: 2,
      color: '#B86699',
      pointInterval: 3600 * 1000 * 24 // This forces the x-axis intervals to be 24 hours
    },
    {
      type: 'line',
      name: 'Average Pressure',
      data: avgpressureData,
      lineWidth: 2,
      color: '#64C3FF',
      pointInterval: 3600 * 1000 * 24
    },
    {
      type: 'line',
      name: 'Min Pressure',
      data: minPressureData,
      lineWidth: 2,
      color: '#97E7CC',
      pointInterval: 3600 * 1000 * 24
    }
  ]

  return (
    {
      chart: {
        type: 'line',
        style: {
          fontSize: '16px',
          fontFamily: 'Honeywell Sans Web'
        }
      },
      title: {
        text: ''
      },
      series: seriesOptions,
      legend: {
        itemStyle: {
          fontWeight: '400',
          fontSize: '16px'
        }
      },

      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%d %b %Y %H:%M' // ex- 01 Jan 2016
        },
        title: {
          text: 'Date'
        }
      },
      yAxis: {
        title: {
          text: 'Pressure Reading'
        },
        min: 0
      },

      plotOptions: {
        series: {
          marker: {
            enabled: true,
            radius: 1
          }
        }
      }
    }
  )
}

export function getTemperatureDataOptions (
  avgTemperatureData: Array<(number | [(number | string), (number | null)] | null | PointOptionsObject)> = [[null, null]]
): Options {
  const seriesData: SeriesLineOptions[] = [
    {
      type: 'line',
      name: 'Average Temperature',
      data: avgTemperatureData,
      pointInterval: 3600 * 1000 * 24 // one day
    }
  ]

  return ({
    chart: {
      // type: 'spline'
      type: 'line',
      style: {
        fontSize: '16px',
        fontFamily: 'Honeywell Sans Web'
      }
    },
    title: {
      text: ''
    },
    series: seriesData,
    legend: {
      itemStyle: {
        fontWeight: '400',
        fontSize: '16px'
      }
    },
    xAxis: {
      title: {
        text: 'Date'
      },
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%d %b %Y %H:%M' // ex- 01 Jan 2016
      }

    },
    yAxis: {
      title: {
        text: 'Temperature (F)'
      }
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          radius: 2
        }
      }
    }
  })
}

export function getConsumptionDataOptions (
  consumptionData: any = [[null, null, null]], unit = ''
): Options {
  const unitText = unit !== '' ? `(${unit})` : ''
  const seriesData: SeriesAreasplineOptions[] = [
    {
      type: 'areaspline',
      name: `Consumption${unitText}`,
      data: consumptionData,
      lineWidth: 2,
      lineColor: '#97E7CC',
      fillOpacity: 0.1,
      events: {
        legendItemClick: function () {
          return false
        }
      },
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 4,
        fillColor: '#97E7CC',
        lineColor: '#000',
        lineWidth: 1,
        states: {
          hover: {
            enabled: true
          }
        },
        fillOpacity: 1
      }
    }
  ]

  return ({
    chart: {
      type: 'areaspline',
      style: {
        fontSize: '16px',
        fontFamily: 'Honeywell Sans Web'
      }
    },
    title: {
      text: ''
    },
    series: seriesData,
    colors: [
      {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1
        },
        stops: [
          [0, '#97E7CC'],
          [1, '#C4C4C400']
        ]
      }
    ],
    legend: {
      itemStyle: {
        fontWeight: '400',
        fontSize: '16px'
      },
      backgroundColor: 'transparent'
    },
    xAxis: {
      title: {
        text: 'Date'
      },
      type: 'datetime',
      startOnTick: true
    },
    yAxis: {
      title: {
        text: `Consumption ${unitText}`
      }
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          radius: 2
        }
      }
    },
    // istanbul ignore next
    tooltip: {
      formatter: function () {
        const xValue = this.x
        let extraFieldValue = 'Not Available'
        let consumption = 'Not Available'

        for (let i = 0; i < consumptionData.length; i++) {
          if (xValue === consumptionData[i][0]) {
            extraFieldValue = consumptionData[i][2]
            break
          }
        }

        if (this.y !== undefined && this.y !== null) {
          consumption = this.y.toString()
        }

        const date = this.x !== undefined ? parseDateInAOHstandard(this.x as string) : ''

        return (
          '<span style="color:' + '">\u25CF</span> ' +
          // Highcharts.dateFormat('%A, %b %e, %H:%M:%S', this.x as number).toString() + '<br/>' +
            date + '<br/>' +
          '•&nbsp;&nbsp;' + this.series.name.toString() + ': ' + consumption + '</b><br/>' +
          '•&nbsp;&nbsp;Meter index' + ((unitText.length > 0) ? ' ' + unitText : '') + ': ' + extraFieldValue.toString()
        )
      }
    }
  })
}

export function getAmiDataOptions (color: string, ChartData: any, containerRef: any, hrRef: any, titleRef: any, width: number): Options {
  const seriesData: any = [
    {
      name: 'Snapshot Register Reads (%)',
      data: ChartData,
      pointInterval: 3600 * 1000 * 24,
      lineWidth: '1.5px',
      lineColor: color
    }
  ]

  // let width = 300
  // if (containerRef !== null) {
  //   // istanbul ignore else
  //   // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  //   if (containerRef.current && hrRef.current && titleRef.current) {
  //     // eslint-disable-next-line @typescript-eslint/no-unused-vars
  //     width = containerRef.current.clientWidth - hrRef.current.clientWidth - titleRef.current.clientWidth
  //   }
  // }

  return ({
    chart: {
      type: 'areaspline',
      style: {
        fontSize: '16px',
        fontFamily: 'Honeywell Sans Web'
      },
      backgroundColor: 'transparent',
      height: 100,
      width
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    tooltip: {
      backgroundColor: 'white',
      style: {
        color: 'black',
        fontSize: '14px'
      },
      useHTML: true,
      formatter: function () {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `${convertDateIntoAohStandardUsingDayjs(this.series.data[this.point.index].name)} <br /> Snapshot Register Reads: ${this.series.data[this.point.index].y?.toFixed(2)}%`
      }
    },
    series: seriesData,
    colors: [
      {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1
        },
        stops: [
          [0, `${color}44`],
          [1, `${color}00`]
        ]
      },
      {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1
        },
        stops: [
          [0, '#5E5E5E00'],
          [1, '#5E5E5E00']
        ]
      }
    ],
    legend: {
      enabled: false
    },
    xAxis: {
      type: 'datetime',
      visible: false
    },
    yAxis: {
      visible: false
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          radius: 2
        }
      }
    }
  })
}

export function getCellularOptions (
  consumptionData: any = [[null, null]],
  signalQualityData: any = [[null, null]],
  transmissionData: any = [[null, null]]
): Options {
  const seriesData: SeriesAreasplineOptions[] = [
    {
      type: 'areaspline',
      threshold: 0,
      name: 'Cellular Signal Strength',
      data: consumptionData,
      lineWidth: 3,
      lineColor: '#97E7CC',
      fillOpacity: 0.1,
      events: {
        legendItemClick: function () {
          return false
        }
      },
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 4,
        fillColor: '#97E7CC',
        lineColor: '#000',
        lineWidth: 1,
        states: {
          hover: {
            enabled: true
          }
        },
        fillOpacity: 1
      }
    },
    {
      type: 'areaspline',
      threshold: 0,
      name: 'Signal Quality',
      data: signalQualityData,
      lineWidth: 3,
      lineColor: '#64C3FF',
      fillOpacity: 0.1,
      events: {
        legendItemClick: function () {
          return false
        }
      },
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 4,
        fillColor: '#64C3FF',
        lineColor: '#000',
        lineWidth: 1,
        states: {
          hover: {
            enabled: true
          }
        },
        fillOpacity: 1
      }
    },
    {
      type: 'areaspline',
      threshold: 0,
      name: 'Transmission Power',
      data: transmissionData,
      lineWidth: 3,
      lineColor: '#F566A2',
      fillOpacity: 0.1,
      events: {
        legendItemClick: function () {
          return false
        }
      },
      marker: {
        enabled: true,
        symbol: 'circle',
        radius: 4,
        fillColor: '#F566A2',
        lineColor: '#000',
        lineWidth: 1,
        states: {
          hover: {
            enabled: true
          }
        },
        fillOpacity: 1
      }
    }
  ]

  const colorMap = ['#97E7CC', '#64C3FF', '#F566A2']

  return {
    chart: {
      type: 'areaspline',
      style: {
        fontSize: '16px',
        fontFamily: 'Honeywell Sans Web'
      }
    },
    title: {
      text: ''
    },
    series: seriesData,
    colors: [
      {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 1,
          y2: 0
        },
        stops: [
          [0, 'rgba(151, 231, 204, 0.4)'],
          [1, 'rgba(151, 231, 204, 0)']
        ]
      },
      {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 0,
          y2: 1
        },
        stops: [
          [0, 'rgba(196, 196, 196, 0)'],
          [1, 'rgba(100, 195, 255, 0.4)']
        ]
      },
      {
        linearGradient: {
          x1: 0,
          x2: 0,
          y1: 1,
          y2: 0
        },
        stops: [
          [0, 'rgba(245, 102, 162, 0.4)'],
          [1, 'rgba(196, 196, 196, 0)']
        ]
      }
    ],
    legend: {
      enabled: true,
      useHTML: true,
      itemStyle: {
        fontWeight: '400',
        fontSize: '16px'
      },
      backgroundColor: 'transparent',
      labelFormatter: function () {
        const name = this.name
        const info = networkDetailTooltip(name)
        const color = colorMap[this.index]
        return `<span>
                  <span style="font-size: 17px; color: ${this.visible ? color : '#606063'}">▍</span>
                  <span>${name}</span>
                  <abbr title="${info}" style="text-decoration: none; font-weight: bolder"> ⓘ </abbr>
                </span>`
      },
      align: 'right',
      verticalAlign: 'top',
      y: 5
    },
    xAxis: {
      title: {
        text: 'Date'
      },
      type: 'datetime',
      startOnTick: true
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false
        }
      }
    },
    tooltip: {
      outside: true,
      formatter: function (config) {
        console.log('config', config)
        const point = this
        const y1 = (point.series.chart.series[0] as CustomSeries).processedYData[point.point.index]
        const y2 = (point.series.chart.series[1] as CustomSeries).processedYData[point.point.index]
        const y3 = (point.series.chart.series[2] as CustomSeries).processedYData[point.point.index]
        const CSSUnit = getUnitForKpi('Cellular Signal Strength')
        const SQUnit = getUnitForKpi('Signal Quality')
        const TPUnit = getUnitForKpi('Transmission Power')
        const dateText = parseDateInAOHstandard(this.x as string)

        let tooltipText = ''

        // istanbul ignore else
        // istanbul ignore next
        if (y1 === y2 && y2 === y3) {
          const cellularSignalStrength = y1 !== undefined && y1 !== null ? y1.toString() + ' ' + CSSUnit : 'Not Available'
          const signalQuality = y2 !== undefined && y2 !== null ? y2.toString() + ' ' + SQUnit : 'Not Available'
          const transmissionPower = y3 !== undefined && y3 !== null ? y3.toString() + ' ' + TPUnit : 'Not Available'
          tooltipText = `
            <span style="color:#97E7CC; font-size: 18px">\u25CF</span> <b>Cellular Signal Strength</b><br>
            <span>\u25CF</span> ${dateText}<br>
            <span>\u25CF</span> Cellular Signal Strength: ${cellularSignalStrength}<br>
            _________________________________
            <br>
            <span style="color:#64C3FF; font-size: 18px">\u25CF</span> <b>Signal Quality</b><br>
            <span>\u25CF</span> ${dateText}<br>
            <span>\u25CF</span> Signal Quality: ${signalQuality}<br>
            _________________________________
            <br>
            <span style="color:#F566A2; font-size: 18px">\u25CF</span> <b>Transmission Power</b><br>
            <span>\u25CF</span> ${dateText}<br>
            <span>\u25CF</span> Transmission Power: ${transmissionPower}`
        } else if (this.series.name === 'Transmission Power') {
          if (y1 === y3) {
            const cellularSignalStrength = y1 !== undefined && y1 !== null ? y1.toString() + ' ' + CSSUnit : 'Not Available'
            const transmissionPower = y3 !== undefined && y3 !== null ? y3.toString() + ' ' + TPUnit : 'Not Available'
            tooltipText = `
            <span style="color:#97E7CC; font-size: 18px">\u25CF</span> <b>Cellular Signal Strength</b><br>
            <span>\u25CF</span> ${dateText}<br>
            <span>\u25CF</span> Cellular Signal Strength: ${cellularSignalStrength}<br>
            _________________________________
            <br><br>
            <span style="color:#64C3FF; font-size: 18px">\u25CF</span> <b>Transmission Power</b><br>
            <span>\u25CF</span> ${dateText}<br>
            <span>\u25CF</span> Transmission Power: ${transmissionPower}`
          } else if (y2 === y3) {
            const signalQuality = y2 !== undefined && y2 !== null ? y2.toString() + ' ' + SQUnit : 'Not Available'
            const transmissionPower = y3 !== undefined && y3 !== null ? y3.toString() + ' ' + TPUnit : 'Not Available'
            tooltipText = `
            <span style="color:#F566A2; font-size: 18px">\u25CF</span> <b>Transmission Power</b><br>
            <span>\u25CF</span> ${dateText}<br>
            <span>\u25CF</span> Transmission Power: ${transmissionPower}<br>
            _________________________________
            <br><br>
            <span style="color:#64C3FF; font-size: 18px">\u25CF</span> <b>Signal Quality</b><br>
            <span>\u25CF</span> ${dateText}<br>
            <span>\u25CF</span> Signal Quality: ${signalQuality}`
          } else {
            const consumption = this.y !== undefined && this.y !== null ? this.y.toString() + ' ' + getUnitForKpi(this.series.name) : 'Not Available'
            let color = ''
            color = '#F566A2'
            tooltipText = `
            <span style="color:${color}; font-size: 18px">\u25CF</span> <b>${this.series.name}</b><br>
            <span>\u25CF</span> ${dateText}<br>
            <span>\u25CF</span> ${this.series.name}: ${consumption}`
          }
        } else if (this.series.name === 'Signal Quality') {
          if (y1 === y2) {
            const cellularSignalStrength = y1 !== undefined && y1 !== null ? y1.toString() + ' ' + CSSUnit : 'Not Available'
            const signalQuality = y2 !== undefined && y2 !== null ? y2.toString() + ' ' + SQUnit : 'Not Available'
            tooltipText = `
            <span style="color:#97E7CC; font-size: 18px">\u25CF</span> <b>Cellular Signal Strength</b><br>
            <span>\u25CF</span> ${dateText}<br>
            <span>\u25CF</span> Cellular Signal Strength: ${cellularSignalStrength}<br>
            _________________________________
            <br><br>
            <span style="color:#64C3FF; font-size: 18px">\u25CF</span> <b>Signal Quality</b><br>
            <span>\u25CF</span> ${dateText}<br>
            <span>\u25CF</span> Signal Quality: ${signalQuality}`
          } else if (y3 === y2) {
            const signalQuality = y2 !== undefined && y2 !== null ? y2.toString() + ' ' + SQUnit : 'Not Available'
            const transmissionPower = y3 !== undefined && y3 !== null ? y3.toString() + ' ' + TPUnit : 'Not Available'
            tooltipText = `
            <span style="color:#F566A2; font-size: 18px">\u25CF</span> <b>Transmission Power</b><br>
            <span>\u25CF</span> ${dateText}<br>
            <span>\u25CF</span> Transmission Power: ${transmissionPower}<br>
            _________________________________
            <br><br>
            <span style="color:#64C3FF; font-size: 18px">\u25CF</span> <b>Signal Quality</b><br>
            <span>\u25CF</span> ${dateText}<br>
            <span>\u25CF</span> Signal Quality: ${signalQuality}`
          } else {
            const consumption = this.y !== undefined && this.y !== null ? this.y.toString() + ' ' + getUnitForKpi(this.series.name) : 'Not Available'
            let color = ''
            color = '#64C3FF'
            tooltipText = `
            <span style="color:${color}; font-size: 18px">\u25CF</span> <b>${this.series.name}</b><br>
            <span>\u25CF</span> ${dateText}<br>
            <span>\u25CF</span> ${this.series.name}: ${consumption}`
          }
        } else if (this.series.name === 'Cellular Signal Strength') {
          if (y1 === y3) {
            const cellularSignalStrength = y1 !== undefined && y1 !== null ? y1.toString() + ' ' + CSSUnit : 'Not Available'
            const transmissionPower = y3 !== undefined && y3 !== null ? y3.toString() + ' ' + TPUnit : 'Not Available'
            tooltipText = `
            <span style="color:#97E7CC; font-size: 18px">\u25CF</span> <b>Cellular Signal Strength</b><br>
            <span>\u25CF</span> ${dateText}<br>
            <span>\u25CF</span> Cellular Signal Strength: ${cellularSignalStrength}<br>
            _________________________________
            <br><br>
            <span style="color:#64C3FF; font-size: 18px">\u25CF</span> <b>Transmission Power</b><br>
            <span>\u25CF</span> ${dateText}<br>
            <span>\u25CF</span> Transmission Power: ${transmissionPower}`
          } else if (y1 === y2) {
            const cellularSignalStrength = y1 !== undefined && y1 !== null ? y1.toString() + ' ' + CSSUnit : 'Not Available'
            const signalQuality = y2 !== undefined && y2 !== null ? y2.toString() + ' ' + SQUnit : 'Not Available'
            tooltipText = `
            <span style="color:#97E7CC; font-size: 18px">\u25CF</span> <b>Cellular Signal Strength</b><br>
            <span>\u25CF</span> ${dateText}<br>
            <span>\u25CF</span> Cellular Signal Strength: ${cellularSignalStrength}<br>
            _________________________________
            <br><br>
            <span style="color:#64C3FF; font-size: 18px">\u25CF</span> <b>Signal Quality</b><br>
            <span>\u25CF</span> ${dateText}<br>
            <span>\u25CF</span> Signal Quality: ${signalQuality}`
          } else {
            const consumption = this.y !== undefined && this.y !== null ? this.y.toString() + ' ' + getUnitForKpi(this.series.name) : 'Not Available'
            let color = ''
            if (this.series.name === 'Cellular Signal Strength') {
              color = '#97E7CC'
            } else if (this.series.name === 'Signal Quality') {
              color = '#64C3FF'
            } else if (this.series.name === 'Transmission Power') {
              color = '#F566A2'
            }
            tooltipText = `
            <span style="color:${color}; font-size: 18px">\u25CF</span> <b>${this.series.name}</b><br>
            <span>\u25CF</span> ${dateText}<br>
            <span>\u25CF</span> ${this.series.name}: ${consumption}`
          }
        } else {
          const consumption = this.y !== undefined && this.y !== null ? this.y.toString() + ' ' + getUnitForKpi(this.series.name) : 'Not Available'
          let color = ''
          if (this.series.name === 'Cellular Signal Strength') {
            color = '#97E7CC'
          } else if (this.series.name === 'Signal Quality') {
            color = '#64C3FF'
          } else if (this.series.name === 'Transmission Power') {
            color = '#F566A2'
          }
          tooltipText = `
          <span style="color:${color}; font-size: 18px">\u25CF</span> <b>${this.series.name}</b><br>
          <span>\u25CF</span> ${dateText}<br>
          <span>\u25CF</span> ${this.series.name}: ${consumption}`
        }
        return tooltipText
      }
    }
  }
}
