import React, { useEffect } from 'react'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Button } from './Button'

interface PropsFromParent {
  children?: React.ReactNode
  onClose?: () => void
  onClickPositive?: () => void
  onClickNegative?: () => void
  LabelPositive?: string
  LabelNegative?: string
  buttonTypePositive?: 'hollow' | 'filled'
  buttonTypeNegative?: 'hollow' | 'filled'
  title: string
  positiveDisabled?: boolean
  negativeDisabled?: boolean
  width?: string
  height?: string
  titleStyle?: React.CSSProperties
  sx?: React.CSSProperties
  ariaLabel?: string
}

const Modal = (props: PropsFromParent): JSX.Element => {
  useEffect(() => {
    console.log('modal mounted')
    document.body.style.overflow = 'hidden'
    return () => {
      console.log('modal unmounted')
      document.body.style.overflow = 'unset'
    }
  }, [])
  return (
    <div id='generic-modal' style={{ backgroundColor: '#000000a4', height: '100vh', width: '100vw', position: 'fixed', zIndex: 1000, top: 0, backdropFilter: 'blur(5px)', left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ width: props.width !== undefined ? props.width : '500px', height: props.height !== undefined ? props.height : '224px', backgroundColor: '#202020', padding: '30px', borderRadius: '8px', boxShadow: '28px 28px 32px 12px rgba(0,0,0,0.66)', ...props.sx }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span style={props.titleStyle !== undefined ? { ...props.titleStyle, fontSize: 24, fontWeight: 500 } : { fontSize: 24, fontWeight: 500 }}>{props.title}</span>
          {
            props.onClose !== undefined
              ? <IconButton data-testid='close-btn' aria-label={props.ariaLabel} onClick={props.onClose} style={{ padding: 0, margin: 0 }}><CloseIcon /></IconButton>
              : <></>
          }
        </div>
        {props.children !== undefined ? props.children : ''}
        <div style={{ display: 'flex', justifyContent: 'right', padding: '1em' }}>
          {
            props.LabelNegative !== undefined
              ? <Button primaryColor={(props.negativeDisabled !== undefined && props.negativeDisabled) ? '#B0B0B0' : undefined} disabled={props.negativeDisabled !== undefined ? props.negativeDisabled : false} dataTestId={props.LabelNegative} onClick={props.onClickNegative} type={props.buttonTypeNegative}>{props.LabelNegative}</Button>
              : <></>
          }
          {
            props.LabelPositive !== undefined
              ? <Button primaryColor={(props.positiveDisabled !== undefined && props.positiveDisabled) ? '#B0B0B0' : undefined} disabled={props.positiveDisabled !== undefined ? props.positiveDisabled : false} dataTestId={props.LabelPositive} onClick={props.onClickPositive}>{props.LabelPositive}</Button>
              : <></>
          }
        </div>
      </div>
    </div>
  )
}

export default Modal
